import { getFinancedAmount, getTotalYearOneIncentives } from "./system-cost-utils";

export const SystemCostLabels = {
  TotalProjectCost: "total-project-cost",
  DownPayment: "down-payment",
  FinancedAmount: "financed-amount",
  FinanceDetails: "finance-details",
  TaxCredit: "30%-tax-credit",
  OtherIncentives: "other-incentives",
  TotalYearOneIncentives: "total-year-one-incentives",
};

export const SUGGEST_CHECKBOX_OPTIONS = [
  "total-project-cost",
  "down-payment",
  "30%-tax-credit",
];

export const SYSTEM_COST_OPTIONS = [
  {
    label: SystemCostLabels.TotalProjectCost,
    fieldName: "totalProjectCost",
    suggestFieldName: "suggestTotalProjectCost",
  },
  {
    label: SystemCostLabels.DownPayment,
    fieldName: "downPayment",
    suggestFieldName: "suggestDownPayment",
  },
  {
    label: SystemCostLabels.FinancedAmount,
    fieldName: "financedAmount",
    readOnly: true,
    valueCallback: getFinancedAmount,
  },
  {
    label: SystemCostLabels.FinanceDetails,
  },
  {
    label: SystemCostLabels.TaxCredit,
    fieldName: "taxCredit",
    suggestFieldName: "suggestTaxCredit",
  },
  {
    label: SystemCostLabels.OtherIncentives,
    fieldName: "otherIncentives",
  },
  {
    label: SystemCostLabels.TotalYearOneIncentives,
    fieldName: "totalIncentives",
    readOnly: true,
    valueCallback: getTotalYearOneIncentives,
  },
];

export const FINANCING_RATE_OPTIONS = [
  {
    label: "2.99%",
    id: 2.99,
  },
  {
    label: "3.99%",
    id: 3.99,
  },
  {
    label: "4.99%",
    id: 4.99,
  },
  {
    label: "5.99%",
    id: 5.99,
  },
  {
    label: "6.99%",
    id: 6.99,
  },
  {
    label: "7.99%",
    id: 7.99,
  },
  {
    label: "8.99%",
    id: 8.99,
  },
]

export const FINANCING_TERM_OPTIONS = [
  {
    label: "5 years",
    id: 5,
  },
  {
    label: "10 years",
    id: 10,
  },
  {
    label: "15 years",
    id: 15,
  },
  {
    label: "20 years",
    id: 20,
  },
]
