import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

const CustomInputLabel = ({
  label,
  labelComponent,
  htmlFor,
  labelSize,
  labelStyle,
}) => {
  return (
    <Row style={{ paddingRight: 0 }}>
      <Col xs={6} style={{ display: "flex", alignItems: "flex-end" }}>
        <Form.Label
          htmlFor={htmlFor}
          className={`d-flex align-items-center ${labelSize === "large" ? "big-font" : ""}`}
          style={labelStyle}
        >
          {label}
        </Form.Label>
      </Col>
      <Col xs={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", padding: 0 }}>
        {labelComponent}
      </Col>
    </Row>
  );
};

export default CustomInputLabel;
