import React from "react";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { useUserContext } from "contexts/user-context";
import { useAppNavigate } from "hooks/app-navigate";
import logo from "../../../images/pv-batts-logo-2.png";
import { getEffectiveRole } from "pages/logged-in/logged-in-utils";
import { NAVBAR_OPTIONS, NAVBAR_ROUTES } from "./nav-bar-constants";

function AppNavBar() {
  const navigate = useNavigate();
  const appNavigate = useAppNavigate();
  const { user } = useUserContext();
  const effectiveRole = getEffectiveRole(user);

  const navItems = NAVBAR_OPTIONS[effectiveRole] || [];

  const handleNavigation = (path) => {
    if (user) {
      appNavigate(path);
    } else {
      navigate(path);
    }
  };

  return (
    <Navbar
      bg="light"
      expand="lg"
      style={{ fontSize: "18px", padding: "0 1em" }}
    >
      <Navbar.Brand
        href="#"
        onClick={() =>
          handleNavigation(user ? NAVBAR_ROUTES.HOME : NAVBAR_ROUTES.QUICK_DESIGNER)
        }
        style={{ marginLeft: "1em" }}
      >
        <img
          alt=""
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{" "}
        PV Batts
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          {navItems.map((item) => {
            if (item.isExternal) {
              return (
                <Button
                  key={item.path}
                  href={item.path}
                  variant="warning"
                  style={{
                    fontSize: "18px",
                    justifyContent: "center",
                    align: "center",
                    marginBottom: "0px",
                  }}
                >
                  {item.label}
                </Button>
              );
            }
            return (
              <Nav.Link
                key={item.path}
                active={window.location.pathname.endsWith(item.path)}
                onClick={() => handleNavigation(item.path)}
              >
                {item.label}
              </Nav.Link>
            );
          })}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default AppNavBar;
