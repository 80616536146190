export const getSessionUser = async (api) => {
  try {
    const response = await api.get("/auth/session");
    return response.data?.data?.user;
  } catch (error) {
    console.error("Error checking user session:", error);
  }
  return null;
};

export const getRefData = async ({ type, api }) => {
  const path = `${type}/list/`;
  try {
    const response = await api.get(path);
    return response.data;
  } catch (errors) {
    console.error("Error fetching ref data:", errors);
    throw errors;
  }
}
