export const getOldAverageElectricRate = (gridRowData) => {
  const { oldEnergyCost, siteConsumption } = gridRowData[13]; // Totals row
  
  return (oldEnergyCost / siteConsumption).toFixed(3);
}

export const getOldMonthlyEnergyCost = (gridData) => {
  return Number(gridData[13]?.oldEnergyCost / 12);
}

export const getNewAverageElectricRate = (gridRowData) => {
  const { newRawEnergyCost, siteConsumption } = gridRowData[13]; // Totals row

  return (newRawEnergyCost / siteConsumption).toFixed(3);
};

export const getNewMonthlyEnergyCost = (gridData) => {
  return Number(gridData[13]?.totalCost / 12);
};

export const getElectricBill = (monthlyEnergyCost, fixedCharge) => {
  const totalCosts = monthlyEnergyCost + fixedCharge;
  const newElectricBill = totalCosts < 0 ? 0 : totalCosts;

  return Number(newElectricBill);
};

export const getSolarPayment = (formData) => {
  const {
    financedAmount,
    financingInterestRate,
    financingTerm
  } = formData;
  const monthlyInterestRate = (financingInterestRate / 100) / 12;
  const numPayments = financingTerm * 12;

  return (
    (financedAmount * (monthlyInterestRate) * ((1 + monthlyInterestRate) ** numPayments))
          / (((1 + monthlyInterestRate) ** numPayments) - 1)
    )
}
