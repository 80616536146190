export const buildRateNamesArray = (rateStructures) => {
  return rateStructures.map((datum, index) => {
    return {
      label: `${datum.utility} - ${datum.name}`,
      id: datum.label,
      utility: datum.utility,
      name: datum.name,
    };
  });
}

export const getUniqueRateNames = (rateNames) => {
  const uniqueRateNames = [];

  rateNames.forEach((rateName) => {
    if (uniqueRateNames.length === 0 || !uniqueRateNames.find((uniqueRateName) => uniqueRateName.label === rateName.label)) {
      uniqueRateNames.push(rateName);
    }
  });

  return uniqueRateNames;
}

export const getRateStructureData = (rateStructureLabel, rateStructures) => {
  return rateStructures?.find(
    (structure) => structure.label === rateStructureLabel
  );
}

export const isTouRateSelected = (rateStructureData) => {
  const regex = /tou|time[- ]?of[- ]?use/i; // match any instances of ["tou", "time of use", "time-of-use"], case insensitive
  return regex.test(rateStructureData.name);
}
