import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useUserContext } from "contexts/user-context";
import { Roles } from "./constants";
import ProtectedRoute from "./protected-route";
import TeamPage from "pages/logged-in/team/team";
import QuickDesignerPage from "pages/quick-designer/quick-designer";
import SettingsPage from "./settings";
import LeadsPage from "./leads/components/leads";
import LeadDetailsPage from "./leads/components/lead-details-page";
import AdminHome from "pages/admin/admin-home";
import RegistrationPendingPage from "./registration-pending";
import { getUserHomeRoute } from "./logged-in-utils";

function LoggedInApp() {
  const { user } = useUserContext();

  const RedirectToUserHome = () => {
    const userHomeRoute = getUserHomeRoute(user);
    return <Navigate to={userHomeRoute} replace />;
  };

  return (
    <div style={{ marginTop: "2.5vh" }}>
      <Routes>
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute allowedRoles={[Roles.SUPER_ADMIN]}>
              <AdminHome />
            </ProtectedRoute>
          }
        />

        {/* Regular user routes */}
        <Route path="/app/:teamCode">
          <Route index element={<RedirectToUserHome />} />
          <Route path="home" element={<RedirectToUserHome />} />
          <Route
            path="registration-pending"
            element={<RegistrationPendingPage />}
          />
          <Route path="quick-designer" element={<QuickDesignerPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route
            path="team"
            element={
              <ProtectedRoute
                allowedRoles={[Roles.TEAM_OWNER, Roles.TEAM_MEMBER]}
              >
                <TeamPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="leads/*"
            element={
              <ProtectedRoute
                allowedRoles={[Roles.TEAM_OWNER, Roles.TEAM_MEMBER]}
              >
                <Routes>
                  <Route index element={<LeadsPage />} />
                  <Route path=":leadId" element={<LeadDetailsPage />} />
                </Routes>
              </ProtectedRoute>
            }
          />
        </Route>

        {/* Fallback route */}
        <Route path="*" element={<RedirectToUserHome />} />
      </Routes>
    </div>
  );
}

export default LoggedInApp;
