import useDeviceSize from "hooks/device-size";
import { getNewAverageElectricRate, getElectricBill, getNewMonthlyEnergyCost, getOldAverageElectricRate, getOldMonthlyEnergyCost, getSolarPayment } from "./bill-info-utils";
import { getRateStructureData } from "../../rate-selection/rate-selection-utils";
import { formatAsCurrency } from "utils/lib";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import RateSelection from "../../rate-selection/rate-selection";

const labelStyle = {
  fontWeight: 400,
  fontSize: 22,
};

const valueStyle = {
  fontWeight: 600,
}


function BillInfo({ formData, gridRowData, setFieldValue, rateStructures, hideRateSelection, customEmbedConfig }) {
  const { isMobile } = useDeviceSize();
  const oldElectricRate = getOldAverageElectricRate(gridRowData);
  const oldMonthlyEnergyCost = getOldMonthlyEnergyCost(gridRowData);
  const oldRateStructure = getRateStructureData(formData.oldRateStructure, rateStructures)
  const oldFixedCharge = oldRateStructure?.fixedchargefirstmeter || oldRateStructure?.mincharge;
  const totalOldCost = oldMonthlyEnergyCost + oldFixedCharge;
  const newElectricRate = getNewAverageElectricRate(gridRowData);
  const newMonthlyEnergyCost = getNewMonthlyEnergyCost(gridRowData);
  const newRateStructure = getRateStructureData(formData.newRateStructure, rateStructures);
  const newFixedCharge = newRateStructure.fixedchargefirstmeter || newRateStructure.mincharge;
  const newElectricBill = getElectricBill(newMonthlyEnergyCost, newFixedCharge);
  const solarPayment = getSolarPayment(formData);
  const totalNewCost = newElectricBill + solarPayment;
  const { hideElectricBillAndRateValuesResultsPage } = customEmbedConfig;

  const oldBillOptions = [
    {
      header: "Energy",
      value: oldRateStructure ? formatAsCurrency(oldMonthlyEnergyCost) : "?",
      colWidth: isMobile ? 3 : 2,
    },
    {
      header: "",
      value: "+",
      colWidth: 2,
    },
    {
      header: "Fixed",
      value: oldFixedCharge ? formatAsCurrency(oldFixedCharge) : "?",
      colWidth: 2,
    },
    {
      header: "",
      value: "=",
      colWidth: 2,
    },
    {
      header: "Total",
      value: (oldMonthlyEnergyCost && oldFixedCharge) ? formatAsCurrency(totalOldCost) : "?",
      colWidth: isMobile ? 1 : 2,
    },
    {},
  ];

  const newBillOptions = [
    {
      header: "Electric Bill",
      value: newElectricBill < 0 ? "$0" : formatAsCurrency(newElectricBill),
      colWidth: 3,
    },
    {
      header: "",
      value: "+",
      colWidth: 1,
    },
    {
      header: "Solar Payment",
      value: solarPayment === 0 ? "$0" : formatAsCurrency(solarPayment),
      colWidth: isMobile ? 4 : 3,
    },
    {
      header: "",
      value: "=",
      colWidth: 1,
    },
    {
      header: "Total",
      value: formatAsCurrency(totalNewCost),
      colWidth: 2,
    },
    {
      colWidth: isMobile ? 1 : 2,
    },
  ];

  const renderTable = (tableOptions, prefix) => {
    return (
      tableOptions.map((option, index) => {
        const key = prefix + "-" + index;
        return (
          <Col key={key} xs={option.colWidth}>
            <Row>
              {option.header}
            </Row>
            <Row style={valueStyle}>
              {formatAsCurrency(option.value)}
            </Row>
          </Col>
        )
      })
    )
  }

  return (
    <Container>
      {!hideRateSelection &&
        <Row>
          <RateSelection
            formData={formData}
            rateStructures={rateStructures}
            setFieldValue={setFieldValue}
            resultsPage
          />
        </Row>
      }
      {!hideElectricBillAndRateValuesResultsPage &&
        <Row style={{ padding: "8px 0 0 12px" }}>
          <Col xs={isMobile ? 12 : 6}>
            <Row style={labelStyle}>Old Electric Rate:</Row>
            <Row style={{ ...valueStyle, paddingBottom: "8px" }}>
              {`${
                oldElectricRate === "0.000"
                  ? "Please select a rate to display a comparison"
                  : `$${oldElectricRate}/kWh`
              }`}
            </Row>
            <Row style={labelStyle}>Old Electric Bill:</Row>
            <Row>
              {renderTable(oldBillOptions, "old")}
            </Row>
          </Col>
          <Col style={{ padding: isMobile ? "16px 0px 0px 12px" : "0px 0px 0px 16px" }} xs={isMobile ? 12 : 6}>
            <Row style={{ ...labelStyle }}>New Rate:</Row>
            <Row style={{ ...valueStyle, fontWeight: 600 }}>
              {`$${newElectricRate}/kWh`}
            </Row>
            {!isMobile && <Row style={labelStyle}>&nbsp;</Row>}
            <Row style={isMobile ? { paddingTop: "8px" } : null}>
              {renderTable(newBillOptions, "new")}
            </Row>
          </Col>
        </Row>
      }
    </Container>
  );
}

export default BillInfo;
