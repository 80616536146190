import React from "react";
import store from "store";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import GeneralButton from "components/buttons/general-button";
import useApi from "hooks/api";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/user-context";

function SettingsPage() {
  const api = useApi();
  const navigate = useNavigate();
  const { setUser } = useUserContext();

  const logOut = () => {
    api
      .post("auth/logout", {}, { withCredentials: true })
      .then(() => {
        store.clearAll();
        setUser(null);
        navigate("/login", { replace: true });
      })
      .catch((error) => {
        console.error("Logout failed:", error);
      });
  };

  return (
    <Container style={{ marginTop: "2em" }}>
      <div className="padding" />
      <Row>
        <Col xl={10}>
          <h2> Profile & Settings </h2>
        </Col>
        <Col xl={2}>
          <GeneralButton
            text="Log Out"
            onClick={logOut}
            variant="secondary"
            style={{ borderRadius: "8px"}}
            fullWidth
          />
        </Col>
      </Row>
      <div className="padding" />
    </Container>
  );
}

export default SettingsPage;
