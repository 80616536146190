import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import GeneralSubmitButton from "components/buttons/general-submit-button";
import useApi from "hooks/api";
import RHFEmailInput from "components/inputs/react-hook-form/rhf-email-input";
import RHFPasswordInput from "components/inputs/react-hook-form/rhf-password-input";
import { useSuccessNotification } from "hooks/success-notification";

const schema = yup.object({
  email: yup.string().email().required(),
  password: yup
    .string()
    .required()
    .min(
      10,
      "Password must contain 10 or more characters with at least one of each: uppercase, lowercase, number and special"
    )
    .minLowercase(1, "Password must contain at least 1 lower case letter")
    .minUppercase(1, "Password must contain at least 1 upper case letter")
    .minNumbers(1, "Password must contain at least 1 number")
    .minSymbols(1, "Password must contain at least 1 special character"),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

function ResetPasswordPage() {
  const api = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const [busy, setBusy] = useState(false); 
  const { notifySuccess } = useSuccessNotification();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const token = searchParams.get("token");

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: { email },
  });

  const onSubmit = async (data) => {
    setBusy(true);
    try {
      await api.post("/auth/reset-password", {
        email: data.email,
        password: data.password,
        token,
      });
      notifySuccess("Password reset successfully");
      navigate("/login");
    } catch (error) {
      console.error("Password reset failed", error);
    } finally {
      setBusy(false);
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h2 className="text-center mb-4">Reset Password</h2>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <RHFEmailInput
              label="Email"
              name="email"
              register={register}
              error={errors.email?.message}
              disabled
              required
            />
            <RHFPasswordInput
              label="New Password"
              name="password"
              register={register}
              error={errors.password?.message}
              required
            />
            <RHFPasswordInput
              label="Confirm New Password"
              name="confirmPassword"
              register={register}
              error={errors.confirmPassword?.message}
              required
            />
            <GeneralSubmitButton
              text={busy ? "Resetting..." : "Reset Password"}
              disabled={!isValid}
              onClick={handleSubmit(onSubmit)}
            />
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ResetPasswordPage;
