import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { SuccessProvider } from "hooks/success-notification";
import { ErrorProvider } from "hooks/error-notification";
import { geoAPIKey } from "keys";
import { LoadScript } from "@react-google-maps/api";
import { UserProvider } from "contexts/user-context";
import { RefsProvider } from "contexts/refs-context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <LoadScript googleMapsApiKey={geoAPIKey}>
        <UserProvider>
          <RefsProvider>
            <SuccessProvider>
              <ErrorProvider>
                <App />
              </ErrorProvider>
            </SuccessProvider>
          </RefsProvider>
        </UserProvider>
      </LoadScript>
    </Router>
  </React.StrictMode>
);
