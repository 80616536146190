import React, { useMemo }  from 'react';
import { AgGridReact } from 'ag-grid-react';
import { returnQuickDesignSummaryRows } from 'utils/data.js';
import { toHTMLString } from 'utils/lib.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { useRefs } from 'contexts/refs-context';

function QuickDesignSystemSummary({
  label,
  formData,
  selectedSolarModule,
  selectedInverters,
  selectedBattery,
}) {
  const { refs } = useRefs();
  const id = toHTMLString(label) + '-system-summary-table'
  const defaultColDef={
    flex: 1,
    wrapText: true,
    cellStyle: () => ({
      display: "flex",
      alignItems: "start",
      showHeaders: false,
      fontSize: '18px'
    })
  };

  const rowData = useMemo(
    () => returnQuickDesignSummaryRows(refs, formData, selectedSolarModule, selectedInverters, selectedBattery),
    [refs, formData, selectedSolarModule, selectedInverters, selectedBattery]
  );

  const columnDefs = [
    {
      field: 'label',
      suppressSizeToFit: false,
      minWidth: 275,
    },
    {
      field: 'value',
      suppressSizeToFit: false,
      flex: 2
    }
  ];


  return (
    <div>
      <label
        className='sub-header'
        htmlFor={id}> {label}
      </label>
      <div
        className="ag-theme-alpine-dark"
        style={{
          height: '100%',
          width: '100%',
          display: 'block'
        }}>
        <AgGridReact
          defaultColDef={defaultColDef}
          id={id}
          columnDefs={columnDefs}
          rowData={rowData}
          domLayout='autoHeight'
          headerHeight={0}/>
      </div>
    </div>
  );
}

export default QuickDesignSystemSummary;