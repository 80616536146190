import GeneralButton from "components/buttons/general-button";
import { useAppNavigate } from "hooks/app-navigate";
import React from "react";
import { Container, Row, Col, Table } from "react-bootstrap";

function LeadDetails({ lead }) {
  const navigate = useAppNavigate();

  if (!lead) return null;
  
  const onBack = () => navigate(`/leads`);

  const { userData, designSpecs, signupNotes } = lead;




  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <GeneralButton onClick={onBack} variant="warning" fullWidth={false} formControl={false}>
            Back to Leads
          </GeneralButton>
        </Col>
      </Row>
      <h2>Lead Details</h2>

      <h3>User Information</h3>
      <Table striped bordered hover>
        <tbody>
          <tr>
            <td>Name</td>
            <td>{`${userData.firstName} ${userData.lastName}`}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{userData.email}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>{userData.address}</td>
          </tr>
          <tr>
            <td>I Am The</td>
            <td>{userData.iAmThe}</td>
          </tr>
          {userData.iWorkIn && (
            <tr>
              <td>I Work In</td>
              <td>{userData.iWorkIn}</td>
            </tr>
          )}
        </tbody>
      </Table>

      <h3>Design Specifications</h3>
      <Table striped bordered hover>
        <tbody>
          <tr>
            <td>Solar Module</td>
            <td>{`${designSpecs.solarModule.quantity} x ${designSpecs.solarModule.value}W`}</td>
          </tr>
          <tr>
            <td>Battery</td>
            <td>{`${designSpecs.battery.quantity} x ${designSpecs.battery.value}kWh`}</td>
          </tr>
          <tr>
            <td>Inverters</td>
            <td>
              {designSpecs.inverters
                .map((inv) => `${inv.quantity} x ${inv.value}kW`)
                .join(", ")}
            </td>
          </tr>
          <tr>
            <td>Net Metering</td>
            <td>{designSpecs.netMeteringEnabled ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td>Grid Charge Disabled</td>
            <td>{designSpecs.gridChargeDisabled ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td>Rate Structure</td>
            <td>{`Old: ${designSpecs.oldRateStructure}, New: ${designSpecs.newRateStructure}`}</td>
          </tr>
        </tbody>
      </Table>

      <h3>Financials</h3>
      <Table striped bordered hover>
        <tbody>
          <tr>
            <td>Total Project Cost</td>
            <td>${designSpecs.financials.totalProjectCost.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Down Payment</td>
            <td>${designSpecs.financials.downPayment.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Financed Amount</td>
            <td>${designSpecs.financials.financedAmount.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Financing Term</td>
            <td>{designSpecs.financials.financingTerm} years</td>
          </tr>
          <tr>
            <td>Interest Rate</td>
            <td>{designSpecs.financials.financingInterestRate}%</td>
          </tr>
          <tr>
            <td>Tax Credit</td>
            <td>${designSpecs.financials.taxCredit.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Other Incentives</td>
            <td>${designSpecs.financials.otherIncentives.toFixed(2)}</td>
          </tr>
        </tbody>
      </Table>

      <h3>Site Consumption Data (kWh)</h3>
      <Row>
        {designSpecs.siteConsumptionData.map((consumption, index) => (
          <Col key={index} xs={3} className="mb-2">
            <strong>Month {index + 1}:</strong> {consumption}
          </Col>
        ))}
      </Row>

      {signupNotes && (
        <>
          <h3>Signup Notes</h3>
          <p>{signupNotes}</p>
        </>
      )}
    </Container>
  );
}

export default LeadDetails;
