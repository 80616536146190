import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GeneralSubmitButton from "components/buttons/general-submit-button";
import useApi from "hooks/api";
import TextLink from "components/buttons/text-link-button";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import RHFEmailInput from "components/inputs/react-hook-form/rhf-email-input";

const schema = yup
  .object({
    email: yup.string().email("Invalid email").required("Email is required"),
  })
  .required();

function ForgotPasswordPage() {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await api.post("/auth/forgot-password", { email: data.email });
      setEmailSent(true);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        align: "center",
        justifyContent: "center",
        padding: "0px",
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="align-items-center">
          <Col xl={3} />
          <Col xl={6}>
            <Container className="transparent" style={{ marginTop: "0px" }}>
              <Row>
                <h2 style={{ marginTop: "1em", textDecoration: "underline" }}>
                  {emailSent ? "Email Sent" : "Forgot Password"}
                </h2>
              </Row>
              {emailSent ? (
                <>
                  <Row>
                    <p>
                      An email has been sent. Please check your inbox and click
                      on the link in the email to reset your password.
                    </p>
                  </Row>
                  <Row className="align-items-end">
                    <Container
                      style={{ marginTop: "2em", marginBottom: "0em" }}
                    >
                      <GeneralSubmitButton
                        text="Back to Login"
                        onClick={() => navigate("/login")}
                      />
                    </Container>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <RHFEmailInput
                      label="Email"
                      name="email"
                      register={register}
                      error={errors.email?.message}
                      required
                    />
                  </Row>
                  <Row className="align-items-end">
                    <Container
                      style={{ marginTop: "2em", marginBottom: "0em" }}
                    >
                      <GeneralSubmitButton
                        text={isLoading ? "Sending..." : "Reset Password"}
                        onClick={handleSubmit(onSubmit)}
                        disabled={isLoading || !isValid}
                      />
                    </Container>
                  </Row>
                  <Row>
                    <Col sm={4} />
                    <Col sm={4} className="d-flex justify-content-center">
                      <TextLink to="/login">Back to Login</TextLink>
                    </Col>
                    <Col sm={4} />
                  </Row>
                </>
              )}
            </Container>
          </Col>
          <Col xl={3} />
        </Row>
      </Form>
    </Container>
  );
}

export default ForgotPasswordPage;
