import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextInput from 'components/inputs/text.jsx';
import EmailInput from 'components/inputs/email.jsx';
import PasswordInput from 'components/inputs/password.jsx';
import GeneralSubmitButton from 'components/buttons/general-submit-button.jsx';
import InfoButton from 'components/buttons/info.jsx';
import { validateUserRegistration } from 'utils/validations.js';
import { registerUser } from 'controllers/users';
import useApi from 'hooks/api';
import { useSuccessNotification } from 'hooks/success-notification';
import { useNavigate, useSearchParams } from 'react-router-dom';

function RegisterPage() {
  const api = useApi();
  const { notifySuccess } = useSuccessNotification();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const teamCode = searchParams.get("teamCode");

  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    teamCode,
  });

  const setFieldValue = (key, value) => {
    setFormData(existingValues => ({
      ...existingValues,
      [key] : value
    }))
  }

  const [validationErrors, setValidationErrors] = useState('');
  const [validated, setValidated] = useState(false)

  const onSubmit = async (event) => {
    event.preventDefault();
    const isValid = await validateUserRegistration(
      formData,
      setValidationErrors
    );
    setValidated(true);

    if (isValid) {
      try {
        await registerUser(api, formData, navigate);
        notifySuccess("New user registered successfully");
      } catch (error) {
        console.error("Registration failed:", error);
      }
    }
  };

  const inputStyle = {
    marginTop: '11px'
  }

  return  (
    <Container 
      style={{
        minWidth: '75vw',
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        align: 'center',
        justifyContent: 'center'
      }}>
      <Form noValidate validated={validated}>
        <Row
          className='align-items-center'>
          <Col sm={3}/>
          <Col sm={6}>
            <Container className='transparent'>
              <Container>
                <Container>
                  <Row>
                    <h2
                      style={{
                        marginTop: '1em',
                        textDecoration: 'underline'
                      }}> 
                      Register User
                    </h2>
                  </Row>
                  <Row>
                    <TextInput
                      htmlName='new-user-user-name'
                      displayName='Username'
                      fieldName='username'
                      value={formData.username}
                      setFieldValue={setFieldValue}
                      required={true}
                      invalidMessage={validationErrors['username']}
                      colWidth={12}
                      style={inputStyle}
                      validated={validated}
                    />
                  </Row>
                  <Row>
                    <EmailInput
                      htmlName='new-user-email'
                      displayName='Email'
                      fieldName='email'
                      value={formData.email}
                      validated={validated}
                      setFieldValue={setFieldValue}
                      required={true}
                      invalidMessage={validationErrors['email']}
                      colWidth={12}
                      style={inputStyle}
                    />
                  </Row>
                  <Row>
                    <PasswordInput
                      htmlName='new-user-password'
                      displayName='Password'
                      fieldName='password'
                      value={formData.password}
                      validated={validated}
                      setFieldValue={setFieldValue}
                      required={true}
                      invalidMessage={validationErrors['password']}
                      colWidth={12}
                      style={inputStyle}
                    />
                  </Row>
                  <Row>
                    <PasswordInput
                      htmlName='new-user-confirm-password'
                      displayName='Confirm Password'
                      fieldName='confirmPassword'
                      value={formData.confirmPassword}
                      validated={validated}
                      setFieldValue={setFieldValue}
                      required={true}
                      invalidMessage={validationErrors['confirmPassword']}
                      colWidth={12}
                      style={inputStyle}/>
                  </Row>
                  <Row>
                    <Container>
                      <GeneralSubmitButton
                        style={{
                          marginTop: '2.5em',
                          marginBottom: ''
                        }}
                        text='Register New User'
                        onClick={onSubmit}/>
                    </Container>
                  </Row>
                  <Row>
                    <Col sm={4}/>
                    <Col sm={4}>
                      <InfoButton
                        label='Return to Login'
                        onClick={() => navigate('/login')}
                        style={{
                          marginBottom: '1.5em',
                          marginTop: '1.5em'
                        }}/>
                    </Col>
                    <Col sm={4}/>
                  </Row>
                </Container>
              </Container>
            </Container>
          </Col>
          <Col sm={3}/>
        </Row>
      </Form>
    </Container>
  )
}

export default RegisterPage;