import { Table } from "react-bootstrap";
import { formatRoleForDisplay } from "../team-utils";
import GeneralButton, { ButtonSize } from "components/buttons/general-button";
import { ModalTypes } from "./modals/team-modal-controller";

function MemberTable({
  handleOpenModal,
  isOwner = false,
  members,
  pending,
  setSelectedUser
}) {
  return (
    <Table striped bordered hover>
      <colgroup>
        <col style={{ width: "20%" }} />
        <col style={{ width: "20%" }} />
        <col style={{ width: "20%" }} />
        <col style={{ width: "25%" }} />
        <col style={{ width: "15%" }} />
        {isOwner && <col style={{ width: "15%" }} />}
      </colgroup>
      <thead>
        <tr>
          <th>Username</th>
          <th>Last Name</th>
          <th>First Name</th>
          <th>Email</th>
          <th>Role</th>
          {isOwner && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {members.map((member, index) => {
          const { email, username, firstName, lastName } = member;
          const formattedRole = formatRoleForDisplay(member.teamRoles[member.teamCode]);
          return (
            <tr key={index}>
              <td>{username}</td>
              <td>{lastName || "--"}</td>
              <td>{firstName || "--"}</td>
              <td>{email}</td>
              <td>{formattedRole}</td>
              {isOwner && (
                <td>
                  {pending ? (
                    <GeneralButton
                      variant="primary"
                      size={ButtonSize.SMALL}
                      style={{ margin: "0 0 0 0" }}
                      onClick= {() => handleOpenModal(ModalTypes.ActivateMember, setSelectedUser(member))}
                      fullWidth
                    >
                      Activate
                    </GeneralButton>
                  ) : (
                    <GeneralButton
                      variant="warning"
                      size={ButtonSize.SMALL}
                      style={{ margin: "0 0 0 0" }}
                      onClick= {() => handleOpenModal(ModalTypes.EditMember, setSelectedUser(member))}
                      fullWidth
                    >
                      Edit
                    </GeneralButton>
                  )}
                </td>
              )}
            </tr>
          )
        })}
      </tbody>
    </Table>
  );
}

export default MemberTable;
