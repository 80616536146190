export const getTeamByTeamCode = async (api, teamCode) => {
  try {
    const response = await api.get(`/team/${teamCode}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching team:", error);
    throw error;
  }
};

export const getRoleForTeam = (member, teamCode) => {
  return member.teamRoles[teamCode];
};

export const formatRoleForDisplay = (role) => {
  switch (role) {
    case "teamOwner":
      return "Team Owner";
    case "teamMember":
      return "Team Member";
    case "pending":
      return "Pending";
    default:
      return "No Role";
  }
};

export const addUserToTeam = async (api, teamCode, email) => {
  try {
    return await api.post(`/team/${teamCode}/addMember`, { email });;
  } catch (error) {
    console.error("Error adding member to team:", error);
    throw error;
  }
};

export const removeUserFromTeam = async (api, teamCode, email) => {
  try {
    return await api.post(`/team/${teamCode}/removeMember`, { email });
  } catch (error) {
    console.error("Error removing member from team:", error);
    throw error;
  }
};

export const updateUser = async (api, user, userData) => {
  console.log(user);
  const { teamCode, id: userId } = user;
  try {
    const response = await api.put(`/user/${teamCode}/update/${userId}`, userData);
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};
