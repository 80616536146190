import GeneralButton, { ButtonSize } from "components/buttons/general-button";
import React from "react";
import { Modal } from "react-bootstrap";


function BaseModal({
  show,
  onHide,
  title,
  children,
  confirmButtonText = "Confirm",
  cancelButtonColor = "secondary",
  cancelButtonText = "Cancel",
  confirmButtonColor = "warning",
  hideCancelButton = false,
  onConfirm,
  onCancel,
  isConfirmDisabled = false,
  size = "md",
}) {
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      onHide();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      style={{ padding: "0px 16px 0px 16px" }}
      size={size}
      centered
    >

      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer className="justify-content-between" style={{ padding: "8px 16px" }}>
        {!hideCancelButton && (
          <GeneralButton
            variant={cancelButtonColor}
            onClick={handleCancel}
            size={ButtonSize.SMALL}
            style={{ margin: "8px 0px 8px 0px" }}
          >
            {cancelButtonText}
          </GeneralButton>
        )}
        <GeneralButton
          variant={confirmButtonColor}
          onClick={onConfirm}
          disabled={isConfirmDisabled}
          size={ButtonSize.SMALL}
          style={{ margin: "8px 0px 8px 0px" }}
        >
          {confirmButtonText}
        </GeneralButton>
      </Modal.Footer>
    </Modal>
  );
}

export default BaseModal;
