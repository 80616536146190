import { useAppNavigate } from "hooks/app-navigate";
import React from "react";

function TextLink({ to, children, style }) {
  const navigate = useAppNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    navigate(to);
  };

  return (
    <a
      href={to}
      onClick={handleClick}
      style={{
        textDecoration: "underline",
        color: "inherit",
        cursor: "pointer",
        background: "none",
        border: "none",
        padding: 0,
        font: "inherit",
        ...style,
      }}
    >
      {children}
    </a>
  );
}

export default TextLink;
