import GeneralButton, { ButtonSize } from "components/buttons/general-button";
import BaseModal from "components/modals/base-modal";
import React from "react";
import { Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateUser } from "../../team-utils";
import useApi from "hooks/api";
import { useSuccessNotification } from "hooks/success-notification";
import { ModalTypes } from "./team-modal-controller";

const schema = yup
  .object({
    firstName: yup
      .string()
      .matches(
        /^[A-Za-z]+$/,
        "First name must contain only alphabetic characters"
      )
      .max(30)
      .nullable(),
    lastName: yup
      .string()
      .matches(
        /^[A-Za-z]+$/,
        "Last name must contain only alphabetic characters"
      )
      .max(30)
      .nullable(),
  })
  .required();

function EditMemberModal({ show, onClose, refetchTeam, selectedUser, setModalType }) {
  const api = useApi();
  const { notifySuccess } = useSuccessNotification();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: selectedUser.firstName || "",
      lastName: selectedUser.lastName || "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      await updateUser(api, selectedUser, data);
      notifySuccess("User updated successfully");
      refetchTeam();
      onClose();
    } catch (error) {
      console.error("Error updating user", error);
      onClose();
    }
  };

  const handleRemove = () => {
    setModalType(ModalTypes.RemoveMember);
  };

  return (
    <BaseModal
      show={show}
      onHide={onClose}
      title="Edit Team Member"
      confirmButtonText="Update"
      onConfirm={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Table style={{ marginBottom: "0px" }}>
          <tbody>
            <tr>
              <td>Username</td>
              <td>{selectedUser.username}</td>
              <td></td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{selectedUser.email}</td>
              <td></td>
            </tr>
            <tr>
              <td>First Name</td>
              <td>
                <input
                  {...register("firstName")}
                  className={`form-control ${
                    errors.firstName ? "is-invalid" : ""
                  }`}
                />
                {errors.firstName && (
                  <div className="invalid-feedback">
                    {errors.firstName.message}
                  </div>
                )}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>
                <input
                  {...register("lastName")}
                  className={`form-control ${
                    errors.lastName ? "is-invalid" : ""
                  }`}
                />
                {errors.lastName && (
                  <div className="invalid-feedback">
                    {errors.lastName.message}
                  </div>
                )}
              </td>
              <td></td>
            </tr>
          </tbody>
        </Table>
        <GeneralButton
          variant="danger"
          onClick={handleRemove}
          size={ButtonSize.SMALL}
          style={{ margin: "16px 0 0 0" }}
        >
          Remove Team Member
        </GeneralButton>
      </form>
    </BaseModal>
  );
}

export default EditMemberModal;
