const SOLAR_COST_PER_WATT = 3;
const BATTERY_COST_PER_KWH = 700;
const SUGGESTED_DOWN_PAYMENT_PERCENT = 30;

export const getSuggestedTotalProjectCost = (formData, selectedSolarModule, selectedBattery) => {
  const { solarQuantity, batteryQuantity } = formData;
  const solarArrayCost =
    (selectedSolarModule.value * 1000) * solarQuantity * SOLAR_COST_PER_WATT;
  const batteryCost = selectedBattery.value * batteryQuantity * BATTERY_COST_PER_KWH;

  return Math.round(solarArrayCost + batteryCost);
}

export const getSuggestedDownpayment = (totalProjectCost, selectedSolarModule, selectedBattery) => {
  return Math.round(totalProjectCost * SUGGESTED_DOWN_PAYMENT_PERCENT * .01 || 0)
}

export const getSuggestedTaxCredit = (totalProjectCost, selectedSolarModule, selectedBattery) => {
  return Math.round(totalProjectCost * SUGGESTED_DOWN_PAYMENT_PERCENT * .01 || 0)
}

export const getFinancedAmount = (totalProjectCost, downPayment) => {
  const financedAmount = totalProjectCost - downPayment;

  return financedAmount < 0 ? 0 : financedAmount;
}

export const getTotalYearOneIncentives = (taxCredit, otherIncentives) => {
  return (
    (taxCredit || 0) +
    (otherIncentives || 0)
  );
}

// All of the ||s in this function are to prevent cascading renders as many of these values depend on each other,
// and due to the "suggest" values being optional, we don't always have the values we
// If there is a cleaner way to do this, please fix it.
export const getUpdatedSystemCostValues = (formData, selectedBattery, selectedSolarModule) => {
  const { suggestTotalProjectCost, suggestDownPayment, suggestTaxCredit } = formData;
  const valuesToUpdate = {};

  if (suggestTotalProjectCost) {
    valuesToUpdate.totalProjectCost = getSuggestedTotalProjectCost(formData, selectedSolarModule, selectedBattery);
  }

  if (suggestDownPayment) {
    valuesToUpdate.downPayment = getSuggestedDownpayment(
      valuesToUpdate.totalProjectCost || formData.totalProjectCost,
      selectedSolarModule,
      selectedBattery
    );
  }

  if (suggestTaxCredit) {
    valuesToUpdate.taxCredit = getSuggestedTaxCredit(
      valuesToUpdate.totalProjectCost || formData.totalProjectCost,
      selectedSolarModule,
      selectedBattery
    );
  }

  valuesToUpdate.financedAmount = getFinancedAmount(
    valuesToUpdate.totalProjectCost || formData.totalProjectCost,
    valuesToUpdate.downPayment || formData.downPayment
  );

  valuesToUpdate.totalIncentives = getTotalYearOneIncentives(
    valuesToUpdate.taxCredit || formData.taxCredit,
    formData.otherIncentives
  );

  return valuesToUpdate;
}
