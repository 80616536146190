import React, { useEffect } from "react";

const bannerStyles = {
  display: "flex",
  justifyContent: "space-between",
  margin: "8px 16px",
  fontSize: "18px",
};

const AlertBanner = ({ message, type, onClose }) => {
  useEffect(() => {
    if (type === "success") {
      const timer = setTimeout(onClose, 5000);
      return () => clearTimeout(timer);
    }
  }, [type, onClose]);

  return (
    <div className={`alert alert-${type}`} role="alert" style={bannerStyles}>
      {message}
      <button
        type="button"
        className="btn-close"
        aria-label="Close"
        onClick={onClose}
      />
    </div>
  );
};

export default AlertBanner;
