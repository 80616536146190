import React, { useState, useCallback, useEffect } from "react";
import { GoogleMap } from "@react-google-maps/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const MapWidget = ({
    address,
    updateAddress
  }) => {
  const [map, setMap] = useState(undefined);
  const [center, setCenter] = useState(undefined);

  const geocodeAddress = useCallback(() => {
    if (map) {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address }, (results, status) => {
        if (status === "OK" && results?.length > 0) {
          const location = results[0].geometry.location;
          setCenter({ lat: location.lat(), lng: location.lng() });
        } else {
          console.error(
            "Geocode was not successful for the following reason:",
            status
          );
        }
      });
    }
  }, [address, map]);

  const onLoad = useCallback(
    (map) => {
      setMap(map);
      geocodeAddress();
    },
    [geocodeAddress]
  );

  useEffect(() => {
    geocodeAddress();
  }, [geocodeAddress, address]);

  const handleDrag = () => {
    if (map) {
      const newCenter = map.getCenter();
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ location: newCenter }, (results, status) => {
        if (status === "OK" && results && results.length > 0) {
          const newAddress = results[0].formatted_address;
          updateAddress({ newAddress, centerMap: false })
        } else {
          console.error("Reverse Geocoding failed with status:", status);
        }
      });
    }
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "400px" }}>
      <GoogleMap
        center={center}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        onLoad={onLoad}
        onDragEnd={handleDrag}
        options={{ mapTypeId: "satellite", streetViewControl: false }}
        tilt={0}
        zoom={19}
      />
      <FontAwesomeIcon
        icon={faLocationDot}
        size="2xl"
        color="#B22222"
        style={{
          position: "absolute",
          top: "calc(50% - 16px)",
          left: "50%",
          transform: "translate(-50%, -50%)",
          pointerEvents: "none",
        }}
      />
    </div>
  );
};

export default MapWidget;
