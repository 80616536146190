import React from "react";
import BaseModal from "components/modals/base-modal";
import { removeUserFromTeam } from "../../team-utils";
import useApi from "hooks/api";
import { useSuccessNotification } from "hooks/success-notification";

function RemoveMemberModal({ show, onClose, selectedUser, refetchTeam }) {
  const api = useApi();
  const { notifySuccess } = useSuccessNotification();
  const { teamCode, email } = selectedUser;
  
  const onConfirm = async () => {
    try {
      await removeUserFromTeam(api, teamCode, email);
      notifySuccess("User successfully removed from the team");
      refetchTeam();
      onClose();
    } catch (error) {
      console.error("Error removing user from team", error);
    }
    onClose();
  };
  

  return (
    <BaseModal
      show={show}
      onHide={onClose}
      title="Remove Team Member"
      confirmButtonText="Remove"
      cancelButtonText="Cancel"
      onConfirm={onConfirm}
      confirmButtonColor="danger"
    >
      <p>Are you sure you want to remove user with email {selectedUser.email} from your team?</p>
    </BaseModal>
  );
}

export default RemoveMemberModal;
