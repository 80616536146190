import { Roles } from "pages/logged-in/constants";

export const NAVBAR_ROUTES = {
  HOME: "/",
  TEAM: "/team",
  LEADS: "/leads",
  QUICK_DESIGNER: "/quick-designer",
  SETTINGS: "/settings",
  LOGIN: "/login",
  PRICING: "https://pvbatts.zohosites.com/",
};

export const NAVBAR_OPTIONS = {
  LOGGED_OUT: [
    { label: "Quick Designer", path: NAVBAR_ROUTES.QUICK_DESIGNER },
    { label: "Login", path: NAVBAR_ROUTES.LOGIN },
    { label: "Pricing", path: NAVBAR_ROUTES.PRICING, isExternal: true },
  ],
  [Roles.TEAM_OWNER]: [
    { label: "Team", path: NAVBAR_ROUTES.TEAM },
    { label: "Leads", path: NAVBAR_ROUTES.LEADS },
    { label: "Quick Designer", path: NAVBAR_ROUTES.QUICK_DESIGNER },
    { label: "Settings", path: NAVBAR_ROUTES.SETTINGS },
  ],
  [Roles.TEAM_MEMBER]: [
    { label: "Team", path: NAVBAR_ROUTES.TEAM },
    { label: "Leads", path: NAVBAR_ROUTES.LEADS },
    { label: "Quick Designer", path: NAVBAR_ROUTES.QUICK_DESIGNER },
    { label: "Settings", path: NAVBAR_ROUTES.SETTINGS },
  ],
  [Roles.PENDING]: [
    { label: "Quick Designer", path: NAVBAR_ROUTES.QUICK_DESIGNER },
    { label: "Settings", path: NAVBAR_ROUTES.SETTINGS },
  ],
  [Roles.NO_ROLE]: [
    { label: "Quick Designer", path: NAVBAR_ROUTES.QUICK_DESIGNER },
    { label: "Settings", path: NAVBAR_ROUTES.SETTINGS },
  ],
};
