function isEmpty(item) {
  return (
    item === undefined ||
    item === '' ||
    (Array.isArray(item) && item.every(isEmpty)) ||
    (typeof item === "object" && (item === null || Object.keys(item).length === 0))
  );
}

//This may not be necessary on the front end anymore...
export function arrayObjectToArray(arrayObject) {
  return Array.isArray(arrayObject) ? arrayObject : Object.keys(arrayObject).map((key) => arrayObject[key]);
}

//This function deletes null fields, but 
export function deleteNullFields(object) {
  return Object.fromEntries(
    Object.entries(object)
      .filter(([_, value]) => value !== '' && value !== null)
      .map(([key, value]) => [key, value === Object(value) ? deleteNullFields(value) : value])
  );
}

//addNullFields is used to populate required fields that the user has previously saved as null values
//It is used when a user loads a saved datatype which has been saved with null values in the database which must 
//be reinitialized to their default values
//prototype is typically a "default" object from src/components/objects/defaults.js
export function addNullFields(object, prototype) {
  Object.keys(prototype).forEach(key => {
    let needsToBeAdded = !object[key] || isEmpty(object[key]);
    if (needsToBeAdded) { object[key] = prototype[key]; }
  });
  return object;
}

export function prepareDesignForSave(designForm, user) {
  let preparedDesignForm = deleteNullFields(designForm);

  //siteConsumptionData is required in all circumstances, so will never be null, but is effectively spread-operatored by deleteNullFields
  preparedDesignForm['siteConsumptionData'] = designForm['siteConsumptionData'];

  //Handle incomplete inverter data
  let currentInverterData = arrayObjectToArray(preparedDesignForm.inverterData);

  //Delete null fields converts all arrays to objects, so must convert arrays back to arrays for save
  let preparedInverterData = currentInverterData.filter(inverterDatum => {
      return inverterDatum['id'] && inverterDatum['manufacturer'] && inverterDatum['quantity'];
  });

  if (preparedInverterData.length > 0) {
    preparedDesignForm["inverterData"] = preparedInverterData;
  }
  else {
    delete preparedDesignForm.inverterData;
  }

  preparedDesignForm["owner"] = preparedDesignForm["owner"] || user["id"];
  //preparedDesignForm = addAuditData(preparedDesignForm, user);

  return preparedDesignForm;
}

export function prepareBatteryForSave(battery) {
  let preparedBattery = deleteNullFields(battery);

  if (battery.mountStyles && battery.mountStyles.length > 0) {
    preparedBattery['mountStyles'] = arrayObjectToArray(battery['mountStyles']);
  } else {
    delete preparedBattery.mountStyles
  }
  if (battery.ocpdTypes && battery.ocpdTypes.length > 0) {
    preparedBattery['ocpdTypes'] = arrayObjectToArray(battery['ocpdTypes']);
  } else {
    delete preparedBattery.ocpdTypes
  }

  return preparedBattery;
}

export function returnQuickDesignResultRows(formData, advancedGridRowData, selectedBattery) {
  if (advancedGridRowData && advancedGridRowData.length > 0) {
    const daysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const dailyConsumptionPerMonth = daysPerMonth.map((days, index) => (formData.siteConsumptionData[index] || 0) / days);
    const maxDailyConsumption = Math.max(...dailyConsumptionPerMonth);
    const percentRow = advancedGridRowData[0];
    const totalRow = advancedGridRowData[13];

    const annualConsumption = totalRow ? Math.round(totalRow['systemConsumption']) + ' kWh/year' : 0;
    const solarOffsetPercent = percentRow ? percentRow['solarOffset'] : 0;
    const gridSellback = totalRow ? Math.round(totalRow['gridSellback']) + ' kWh/year' : 0;
    const systemConsumptionPercent = percentRow ? percentRow['systemConsumption'] : 0;
    const gridConsumptionPercent = percentRow ? percentRow['gridConsumption'] : 0;
    const timeOfUsePercent = selectedBattery ? 
      Math.round(selectedBattery.value * formData.batteryQuantity / maxDailyConsumption * 100) + '%' :
      0;
    const fullBackupDays = totalRow ? totalRow['genDays'] + ' days' : 0;
    const electricBillSavings = totalRow ? '$' + Math.round(totalRow['systemConsumption'] * formData.averageBillPerKwh) + '/year' : 0;
    const gridSellbackIncome = 'Coming Soon!';
    return [
      {
        label: 'Base Electric Bill Savings',
        value: electricBillSavings
      },
      {
        label: 'Solar Offset',
        value: solarOffsetPercent
      },
      {
        label: 'Grid Sellback',
        value: gridSellback
      },
      {
        label: 'Grid Sellback Income',
        value: gridSellbackIncome
      },
      {
        label: 'Energy Self Sufficiency',
        value: systemConsumptionPercent
      },
      {
        label: 'Grid Use',
        value:  gridConsumptionPercent
      },
      {
        label: 'Time-of-Use Capability',
        value: timeOfUsePercent
      },
      {
        label: 'Days of Grid/Generator Use',
        value: fullBackupDays
      }
    ];
  }
  else {
    return [];
  }
}
﻿
export function returnQuickDesignerMessage(messages, formData, advancedGridRowData, selectedBattery) {
  if (!advancedGridRowData) {
    return "";
  }
  const percentRow = advancedGridRowData[0];
  const totalRow = advancedGridRowData[13];
  const solarOffsetPercent = percentRow ? percentRow['solarOffset'] : 0;
  const solarOffsetRatio = solarOffsetPercent ? solarOffsetPercent.replace(/%/g,'') / 100 : 0;
  const annualBatteryCapacity = selectedBattery ? selectedBattery['value'] * formData.batteryQuantity * 365 : 0;
  const batterySizeRatio = totalRow ? annualBatteryCapacity / totalRow['siteConsumption'] : 0;

  return (messages && batterySizeRatio && solarOffsetRatio) ? messages.find(message => {
    return (
      batterySizeRatio >= message.batterySizeMin &&
      batterySizeRatio < message.batterySizeMax &&
      solarOffsetRatio >= message.solarOffsetMin &&
      solarOffsetRatio < message.solarOffsetMax
    );
  }) : '';
}

export function returnQuickDesignSummaryRows(
  refs,
  formData,
  selectedSolarModule,
  selectedInverters,
  selectedBattery
) {
  if (refs && formData && selectedSolarModule && selectedInverters && selectedBattery ) {
    const annualConsumption = formData.siteConsumptionData.reduce((total, monthConsumption) => monthConsumption ? monthConsumption + total : total) + 'kWh';
    const solarCapacity = (formData.solarQuantity * selectedSolarModule.value).toFixed(1) + ' kW';
    const inverterCapacity = (formData.inverterData[0].quantity * selectedInverters[0]['value']).toFixed(1) + ' kW';
    const batteryCapacity = (formData.batteryQuantity * selectedBattery.value).toFixed(1) + ' kWh';
    return [
      {
        label: 'Zip Code',
        value: formData.zipCode
      },
            {
        label: 'Annual Electric Consumption',
        value: annualConsumption
      },
      {
        label: 'Solar Capacity',
        value: solarCapacity
      },
      {
        value: `${formData.solarQuantity} × ${selectedSolarModule.label} ${formData.solarQuantity === 1 ? 'module' : 'modules'}`
      },
      {
        label: 'Inverter Capacity',
        value: inverterCapacity
      },
      {
        value: `${formData.inverterData[0].quantity} × ${selectedInverters[0]['label']} ${formData.inverterQuantity === 1 ? 'inverter' : 'inverters'}`
      },
      {
        label: 'Battery Capacity',
        value: batteryCapacity
      },
      {
        value: `${formData.batteryQuantity} × ${selectedBattery.label} ${formData.batteryQuantity === 1 ? 'battery' : 'batteries'}`
      }
    ];
  } else { return;}
}

export function getPreDefinedSystemDesign(
  formData,
  selectedDesignStyle
) {
  if (selectedDesignStyle.label === 'Custom') {
    return formData;
  } else {
    let newFormData = formData;
    let solarModule = selectedDesignStyle.solarModuleRef;
    let solarQuantity = selectedDesignStyle.solarQuantity;
    let inverter = selectedDesignStyle.inverterRef;
    let inverterQuantity = selectedDesignStyle.inverterQuantity;
    let battery = selectedDesignStyle.batteryRef;
    let batteryQuantity = selectedDesignStyle.batteryQuantity
    newFormData['solarQuantity'] = solarQuantity;
    newFormData['solarModule'] = solarModule.id;
    newFormData['inverterData'] = [{
      id: inverter.id,
      quantity: inverterQuantity
    }];
    newFormData['batteryQuantity'] = batteryQuantity;
    newFormData['battery'] = battery.id;
    return newFormData;
  }
}
