import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { questionMarkIcon } from '../objects/icons';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from "react-bootstrap/Tooltip";

// TODO: Make checkbox handle its own checked state
function SingleCheckbox({id, label, checked, onChange, disabled, tooltip, style}) {
  const renderTooltip = (props) => <Tooltip {...props}>{tooltip}</Tooltip>;

  return (
    <Form.Group
      as={Col}
      style={{
        marginTop: "11px",
        display: "flex",
        alignItems: "center",
        ...style
      }}
    >
      <Form.Check
        id={id}
        label={label}
        checked={checked}
        onChange={onChange ? () => onChange(!checked) : null}
        disabled={disabled}
      />
      <div>
        {tooltip ? (
          <div
            style={{
              display: "inline",
              marginLeft: "6px",
            }}
          >
            <OverlayTrigger placement="top" overlay={renderTooltip}>
              {questionMarkIcon}
            </OverlayTrigger>
          </div>
        ) : (
          <div />
        )}
      </div>
    </Form.Group>
  );
}

export default SingleCheckbox;