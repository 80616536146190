import React from 'react';
import DropdownInput from 'components/inputs/dropdown';
import Row from 'react-bootstrap/esm/Row';
import { FINANCING_RATE_OPTIONS, FINANCING_TERM_OPTIONS } from './constants';

const dropdownStyle = {
  margin: 0,
}

export function FinancingDropdowns({ formData, setFieldValue}) {
  return (
    <Row key="financing-dropdowns" style={{ padding: "6px 12px 0px 12px" }}>
      <DropdownInput
        colWidth={6}
        onChange={(e) => setFieldValue("financingInterestRate", Number(e.target.value))}
        options={FINANCING_RATE_OPTIONS}
        style={dropdownStyle}
        value={formData.financingInterestRate}
      />
      <DropdownInput
        colWidth={6}
        onChange={(e) => setFieldValue("financingTerm", Number(e.target.value))}
        options={FINANCING_TERM_OPTIONS}
        style={dropdownStyle}
        value={formData.financingTerm}
      />
    </Row>
  );
}

export default FinancingDropdowns;