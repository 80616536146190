import { useEffect, useState, useMemo, useCallback } from "react";

const useDeviceSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = useCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return useMemo(() => {
    const isMobile = windowSize.width < 576 || windowSize.height < 576;
    const isTablet = windowSize.width >= 576 && windowSize.width < 1180;
    const isDesktop = windowSize.width >= 1180;
    return { isMobile, isTablet, isDesktop };
  }, [windowSize.width, windowSize.height]);
};

export default useDeviceSize;
