import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/user-context";

function ProtectedRoute({ children, allowedRoles }) {
  const [isChecking, setIsChecking] = useState(true);
  const navigate = useNavigate();
  const { user } = useUserContext();

  useEffect(() => {
    const userTeamRole = user.teamRoles[user.teamCode];
    const globalRoles = user.globalRoles;
    const userIsAllowed = allowedRoles.includes(userTeamRole) || allowedRoles.some(role => globalRoles.includes(role));

    if (!user) {
      navigate("/login", { replace: true });
    } else if (!userIsAllowed) {
      navigate("/unauthorized", { replace: true });
    } else {
      setIsChecking(false);
    }
  }, [user, allowedRoles, navigate]);

  if (isChecking) {
    return null;
  }

  return children;
}

export default ProtectedRoute;
