export const getAllTeams = async (api) => {
  const response = await api.get(`/teams`);

  return response.data;
}

export const createTeam = async (api, formData, email, teamOwners) => {
  const { name, description, embedConfig } = formData;
  const { defaultSolarModule, defaultBattery } = embedConfig;

  const requestBody = {
    name,
    description,
    createdBy: email,
    modifiedBy: email,
    embedConfig: {
      ...formData.embedConfig,
      defaultSolarModule: {
        value: Number(defaultSolarModule.value),
        quantity: Number(defaultSolarModule.quantity),
      },
      defaultBattery: {
        value: Number(defaultBattery.value),
        quantity: Number(defaultBattery.quantity),
      },
    },
    teamOwners,
  };

  const response = await api.post("/team/create", requestBody);
  return response.data;
};

const cleanRequestBody = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") {
      cleanRequestBody(obj[key]);
    } else if (obj[key] === undefined) {
      delete obj[key];
    }
  });
};

export const updateTeam = async (api, formData, email, originalTeamCode) => {
  const { name, description, teamCode: newTeamCode, embedConfig } = formData;
  const { defaultSolarModule, defaultBattery } = embedConfig;

  embedConfig.defaultSolarModule.value = Number(defaultSolarModule.value);
  embedConfig.defaultSolarModule.quantity = Number(defaultSolarModule.quantity);
  embedConfig.defaultBattery.value = Number(defaultBattery.value);
  embedConfig.defaultBattery.quantity = Number(defaultBattery.quantity);

  const requestBody = {
    name: name,
    description: description,
    teamCode: newTeamCode,
    modifiedBy: email,
    embedConfig
  };

  // Remove any undefined values
  cleanRequestBody(requestBody);

  try {
    const response = await api.put(`/team/update/${originalTeamCode}`, requestBody);
    return response.data;
  } catch (error) {
    console.error("Error updating team:", error);
    throw error;
  }
};
