import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useApi from "hooks/api";
import { addUserToTeam } from "../../team-utils";
import { useUserContext } from "contexts/user-context";
import { useErrorNotification } from "hooks/error-notification";
import { useSuccessNotification } from "hooks/success-notification";
import BaseModal from "components/modals/base-modal";
import RHFEmailInput from "components/inputs/react-hook-form/rhf-email-input";

const schema = yup
  .object({
    email: yup.string().email("Invalid email").required("Email is required"),
  })
  .required();

function AddMemberModal({ onClose, refetchTeam, show }) {
  const api = useApi();
  const { user } = useUserContext();
  const { notifyError } = useErrorNotification();
  const { notifySuccess } = useSuccessNotification();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onConfirm = handleSubmit(async (data) => { 
    try {
      const response = await addUserToTeam(api, user.teamCode, data.email);

      if (response.status === 204) {
        notifyError(
          `User with email ${data.email} was not found. Please have them create an account and then try again.`
        );
      } else {
        const { roleAdded } = response.data.data;
        if (roleAdded === false) {
          notifyError(
            `User with email ${data.email} is already a member of the team`
          );
        } else {
          notifySuccess("User added to team successfully");
          refetchTeam();
        }
      }
    } catch (error) {
      console.error("Error adding user to team", error);
      notifyError("An error occurred while adding the user to the team");
    }
    onClose();
  });

  return (
    <BaseModal
      show={show}
      onHide={onClose}
      title="Add Team Member"
      confirmButtonText="Add Member"
      onConfirm={onConfirm}
      confirmDisabled={!isValid}
    >
      <form>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email address
          </label>
          <RHFEmailInput
            type="email"
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
            id="email"
            {...register("email")}
          />
          {errors.email && (
            <div className="invalid-feedback">{errors.email.message}</div>
          )}
        </div>
      </form>
    </BaseModal>
  );
}

export default AddMemberModal;
