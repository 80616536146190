import GeneralButton from "components/buttons/general-button";
import React from "react";
import { useNavigate } from "react-router-dom";

function UnauthorizedPage() {
  const navigate = useNavigate();

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6 text-center">
          <h1 className="mb-4">403 Forbidden</h1>
          <p className="lead mb-4">
            Sorry, you don't have permission to access this page.
          </p>
          <GeneralButton
            text="Go home"
            onClick={() => navigate("/app")}
            variant="primary"
            style={{
              margin: "16px auto",
              maxWidth: "200px",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default UnauthorizedPage;
