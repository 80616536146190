import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { htmlStringToDisplay } from 'utils/lib.js';
import { questionMarkIcon } from '../objects/icons.jsx';

function EmailInput({style, htmlName, displayName, fieldName, tooltip, placeholder, value, setFieldValue, onBlur, colWidth, readOnly, invalidMessage, required, positionLabelAdjacent}) {
  const label = displayName || htmlStringToDisplay(htmlName);
  const renderTooltip = props => (
    <Tooltip {...props}>{ tooltip }</Tooltip>
  );

  return (
    <Form.Group 
      as={positionLabelAdjacent ? Row : Col}
      xl={colWidth}
      style={style ? style : {marginTop: '20px'}}>
      <Form.Label
        htmlFor={htmlName}
        className='big-font'> { label + ': ' }
        <span>
          {
            tooltip ? 
            (
              <div style={{
                display: 'inline',
                marginLeft: '6px',
              }}>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  { questionMarkIcon }
                </OverlayTrigger>
              </div>
            ) : 
            (<div/>)
          }
        </span>
      </Form.Label>
      <Form.Control
        type='email'
        placeholder={placeholder ? placeholder : label}
        id={htmlName}
        value={value}
        onChange={e => { setFieldValue(fieldName,e.target.value) }}
        onBlur={() => onBlur ? onBlur() : {}}
        disabled={readOnly === true}
        required={required}/> 
      <Form.Control.Feedback type="invalid">
        { invalidMessage ? invalidMessage : `Please enter a valid email`}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export default EmailInput;