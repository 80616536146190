import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../images/pv-batts-logo-2.png";

function AdminNavBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const ADMIN_ROUTES = {
    TEAMS: "/admin/teams",
    USERS: "/admin/users",
    SETTINGS: "/admin/settings",
  };

  const adminNavItems = [
    { label: "Teams", path: ADMIN_ROUTES.TEAMS },
    { label: "Users", path: ADMIN_ROUTES.USERS },
    { label: "Settings", path: ADMIN_ROUTES.SETTINGS },
  ];

  return (
    <Navbar
      bg="light"
      expand="lg"
      style={{ fontSize: "18px", padding: "0 1em" }}
    >
      <Navbar.Brand
        href="#"
        onClick={() => navigate(ADMIN_ROUTES.TEAMS)}
        style={{ marginLeft: "1em" }}
      >
        <img
          alt=""
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{" "}
        PV Batts Admin
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          {adminNavItems.map((item) => (
            <Nav.Link
              key={item.path}
              active={location.pathname === item.path}
              onClick={() => navigate(item.path)}
            >
              {item.label}
            </Nav.Link>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default AdminNavBar;
