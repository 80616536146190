import { isEmpty } from './lib.js';
import * as yup from 'yup';
import YupPassword from 'yup-password'
YupPassword(yup) // extend yup

export async function validateUserRegistration(formData, setValidationErrors) {
  const userRegistrationSchema = yup.object({
    email: yup.string().email().required(),
    username: yup.string()
      .min(3, 'Username must be between 3 and 32 characters long')
      .max(32, 'Username must be between 3 and 32 characters long')
      .required('Username is required'),
    password: yup.string().required().min(
        10,
        'Password must contain 10 or more characters with at least one of each: uppercase, lowercase, number and special'
      ).minLowercase(
        1,
        'Password must contain at least 1 lower case letter'
      )
      .minUppercase(
        1,
        'Password must contain at least 1 upper case letter'
      ).minNumbers(
        1,
        'Password must contain at least 1 number'
      ).minSymbols(
        1,
        'Password must contain at least 1 special character'
      ),
    confirmPassword: yup.string().required().oneOf([yup.ref('password'), null], 'Passwords must match')
  });

  try {
    await userRegistrationSchema.validate(formData, { abortEarly: false });
    setValidationErrors({});
    return true;
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      const errors = err.inner.reduce((accumulator, error) => {
        return {
          ...accumulator,
          [error.path]: error.message,
        };
      }, {});

      setValidationErrors(errors);
    }
    return false;
  }
}

export function validateSystemDesign(formData) {
  let errors = {};
  // Convert empty fields to an empty string so we can use validator functions
  if (isEmpty(formData.newRateStructure)) {
    errors.newRateStructure = 'Please select a rate structure'; 
  }
  return errors;
}

export function validateMailingListSignup(formData) {
  let errors = {};
  if (isEmpty(formData.email)) {
    errors.email = 'Please enter an email address'; 
  }
  if (isEmpty(formData.iAmThe)) {
    errors.iAmThe = 'Please select a value'; 
  }
  if (formData.iAmThe === 'company' && isEmpty(formData.iWorkIn)) {
    errors.iWorkIn = 'Please select a value';
  }
  return errors;
}

export function validateEstimateWidget(formData) {
  let errors = {};
  if (isEmpty(formData.totalProjectCost)) {
    errors.totalProjectCost = 'Please enter your project cost, or use a suggested value'; 
  }
  if (isEmpty(formData.downPayment)) {
    errors.downPayment = 'Please enter your down payment, or use a suggested value'; 
  }
  if (isEmpty(formData.financingInterestRate)) {
    errors.financingInterestRate = 'Please enter an interest rate';
  }
  if (isEmpty(formData.financingTerm)) {
    errors.financingTerm = 'Please enter a loan duration';
  }
  return errors;
}

export function emailIsValid(email) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}