import React, { createContext, useCallback, useContext, useState } from "react";

const ErrorContext = createContext();

export const useErrorNotification = () => useContext(ErrorContext);

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const notifyError = useCallback((errorMessage) => {
    setError(errorMessage);
  }, []);

  const clearError = useCallback(() => {
    setError(null);
  }, []);
  return (
    <ErrorContext.Provider value={{ error, notifyError, clearError }}>
      {children}
    </ErrorContext.Provider>
  );
};
