import React, { createContext, useContext, useState } from "react";

const RefsContext = createContext(null);

export const RefsProvider = ({ children }) => {
  const [refs, setRefs] = useState([]);

  return (
    <RefsContext.Provider value={{ refs, setRefs }}>
      {children}
    </RefsContext.Provider>
  );
};

export const useRefs = () => {
  const context = useContext(RefsContext);
  if (context === undefined) {
    throw new Error("useRefs must be used within a RefsProvider");
  }
  return context;
};
