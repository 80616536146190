import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import QuickDesignerPage from "pages/quick-designer/quick-designer";
import LoginPage from "./login";
import RegisterPage from "./register";
import ForgotPasswordPage from "./forgot-password";
import ResetPasswordPage from "./reset-password";

function LoggedOutApp() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/quick-designer" element={<QuickDesignerPage />} />
      <Route path="*" element={<Navigate to="/quick-designer" replace />} />
    </Routes>
  );
}

export default LoggedOutApp;
