import LoadingSpinner from "components/widgets/loading-spinner";
import useApi from "hooks/api";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getLeadById } from "../leads-utils";
import LeadDetails from "./lead-details";

function LeadDetailsPage() {
  const api = useApi();
  const [lead, setLead] = useState(null);
  const [loading, setLoading] = useState(true);

  const { teamCode, leadId } = useParams();

  useEffect(() => {
    async function fetchLead() {
      try {
        const leadData = await getLeadById(api, teamCode, leadId);
        setLead(leadData);
      } catch (err) {
        console.error("Failed to fetch lead", err);
      } finally {
        setLoading(false);
      }
    }

    fetchLead();
  }, [teamCode, leadId, api]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return lead ? <LeadDetails lead={lead} /> : <div>Lead not found</div>;
}

export default LeadDetailsPage;
