import { useState, useEffect } from "react";
import useApi from "./api";
import { getRefData, getSessionUser } from "utils/app-utils";
import { useUserContext } from "contexts/user-context";
import { useRefs } from "contexts/refs-context";

function useInitialization() {
  const [isLoading, setIsLoading] = useState(true);
  const api = useApi();
  const { setUser } = useUserContext();
  const { setRefs } = useRefs();

  useEffect(() => {
    async function initialize() {
      try {
        const [userData, refData] = await Promise.all([
          getSessionUser(api),
          getRefData({ type: "ref", api }),
        ]);
        setUser(userData || null);
        if (refData && refData.length > 0) {
          setRefs(refData);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Initialization error:", error);
        setIsLoading(false);
      }
    }

    initialize();
  }, [api, setUser, setRefs]);

  return { isLoading };
}

export default useInitialization;
