import { Roles } from "pages/logged-in/constants";

export const getEffectiveRole = (user) => {
  if (!user) return "LOGGED_OUT";

  if (user.globalRoles?.includes(Roles.SUPER_ADMIN)) {
    return Roles.SUPER_ADMIN;
  }

  if (!user.teamCode || !user.teamRoles) {
    return Roles.NO_ROLE;
  }

  const teamRole = user.teamRoles[user.teamCode];

  if (teamRole === Roles.TEAM_OWNER) {
    return Roles.TEAM_OWNER;
  } else if (teamRole === Roles.TEAM_MEMBER) {
    return Roles.TEAM_MEMBER;
  } else if (teamRole === Roles.PENDING) {
    return Roles.PENDING;
  }

  return Roles.NO_ROLE;
};

export function getUserHomeRoute(user) {
  const userRole = getEffectiveRole(user);
  switch (userRole) {
    case Roles.SUPER_ADMIN:
      return "/admin/teams";
    case Roles.TEAM_OWNER:
      return `/app/${user.teamCode}/team`;
    case Roles.TEAM_MEMBER:
      return `/app/${user.teamCode}/leads`;
    case Roles.PENDING:
      return `/app/${user.teamCode}/registration-pending`;
    case Roles.NO_ROLE:
      return `/app/${user.teamCode}/quick-designer`;
    case Roles.LOGGED_OUT:
    default:
      return "/quick-designer";
  }
}
