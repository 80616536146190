import React from 'react';
import Button from 'react-bootstrap/Button';

function InfoButton({style, label, onClick, readOnly, showWhen}) {
  return (
    <Button
      variant='link'
      className='btn form-control'
      type='button'
      disabled={readOnly}
      style={style ? style : {
        display: (showWhen !== false ? 'flex' : 'none'),
        justifyContent: 'center',
        alignItem: 'center'
      }}
      onClick={ onClick }> {label}
    </Button>
  )
}

export default InfoButton;