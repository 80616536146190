import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import EmailInput from 'components/inputs/email';
import PasswordInput from 'components/inputs/password';
import GeneralSubmitButton from 'components/buttons/general-submit-button';
import SingleCheckbox from 'components/inputs/single-checkbox';
import { loginUser } from 'controllers/users.js';
import useApi from 'hooks/api';
import { useUserContext } from 'contexts/user-context';
import TextLink from 'components/buttons/text-link-button';

function LoginPage() {
  const api = useApi();
  const { setUser } = useUserContext();
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
    persistLogin: false,
  });
  const setLoginDataField = (key, value) => {
    setLoginData(existingValues => ({
      ...existingValues,
      [key] : value
    }))
  }

  return (
    <Container 
      style={{
        display: 'flex',
        flexDirection: 'column',
        align: 'center',
        justifyContent: 'center',
        padding: "0px"
      }}>
      <Form>
        <Row
          className='align-items-center'>
          <Col xl={3}/>
          <Col xl={6}>
            <Container
              className='transparent'
              style={{
                marginTop: '0px',
              }}>
              <Row>
                <h2
                  style={{
                    marginTop: '1em',
                    textDecoration: 'underline'
                  }}>
                  Login
                </h2>
              </Row>
              <Row>
                <EmailInput
                  style={{
                    marginTop: '11px'
                  }}
                  htmlName='login-email'
                  displayName='Email'
                  fieldName='email'
                  value={loginData.email}
                  setFieldValue={setLoginDataField}
                  colWidth={12}/>
              </Row>
              <Row>
                <PasswordInput
                  htmlName='login-password'
                  displayName='Password'
                  fieldName='password'
                  value={loginData.password}
                  setFieldValue={setLoginDataField}
                  colWidth={12}/>
              </Row>
              <Row>
                <SingleCheckbox
                  label='Remain logged in'
                  checked={loginData.persistLogin}
                  onChange={() => setLoginData(prevData => ({
                    ...prevData,
                    persistLogin: !prevData.persistLogin
                  }))}
                />
              </Row>
              <Row className="align-items-end">
                <Container
                  style={{
                    marginTop: '2em',
                    marginBottom: '0em'
                  }}>
                  <GeneralSubmitButton
                    text='Login'
                    onClick={() => loginUser(api, loginData, setUser)}
                  />
                </Container>
              </Row>
              <Row>
                <Col sm={4} />
                <Col sm={4} className="d-flex justify-content-center">
                  <TextLink to="/forgot-password">Forgot Password?</TextLink>
                </Col>
                <Col sm={4} />
              </Row>
              {/* <Row>
                <Col sm={4}/>
                <Col sm={4}>
                  <InfoButton
                    label='Register Account'
                    style={{
                      marginBottom: '1.5em'
                    }}/>
                </Col>
                <Col sm={4}/>
              </Row> */}
            </Container>
          </Col>
          <Col xl={3}/>
        </Row>
      </Form>
    </Container>
  );
};

export default LoginPage;
