import React from "react";
import { useSuccessNotification } from "hooks/success-notification";
import useApi from "hooks/api";
import BaseModal from "components/modals/base-modal";
import { addUserToTeam } from "../../team-utils";
import { ModalTypes } from "./team-modal-controller";

function ActivateMemberModal({ show, onClose, refetchTeam, selectedUser, setModalType }) {
  const api = useApi();
  const { notifySuccess } = useSuccessNotification();
  const { teamCode, email } = selectedUser;

  const handleActivate = async () => {
    try {
      await addUserToTeam(api, teamCode, email);
      notifySuccess("User successfully activated and added to the team"); 
      refetchTeam();
      onClose();
    } catch (error) {
      console.error("Error activating user", error);
    }
  };

  return (
    <BaseModal
      show={show}
      onCancel={() => setModalType(ModalTypes.RemoveMember)}
      onHide={onClose}
      title="Activate Team Member"
      confirmButtonText="Activate"
      cancelButtonColor="danger"
      cancelButtonText="Remove Member"
      onConfirm={handleActivate}
    >
      <p>
        Are you sure you want to activate user with email{" "}
        <span style={{ color: "blue" }}>{email}</span> and add them
        to your team? They will gain access to your team's leads.
      </p>
    </BaseModal>
  );
}

export default ActivateMemberModal;
