import { useUserContext } from "contexts/user-context";
import useApi from "hooks/api";
import { useEffect, useState } from "react";
import { getTeamLeads } from "../leads-utils";
import LoadingSpinner from "components/widgets/loading-spinner";
import GeneralButton, { ButtonSize } from "components/buttons/general-button";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useAppNavigate } from "hooks/app-navigate";

function LeadsPage() {
  const api = useApi();
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user: currentUser } = useUserContext();
  const navigate = useAppNavigate();

  useEffect(() => {
    async function fetchLeads() {
      if (!currentUser?.teamCode) return;
  
      try {
        const leadsData = await getTeamLeads(api, currentUser.teamCode);
        setLeads(leadsData);
      } catch (err) {
        console.error("Failed to fetch leads");
      } finally {
        setLoading(false);
      }
    }
  
    fetchLeads();
  }, [currentUser.teamCode, api]);

  if (loading) {
    return <LoadingSpinner />
  };

  return (
    <Container>
      <Row className="mt-4 mb-4">
        <Col>
          <h2>Team Leads</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Last Name</th>
                <th>First Name</th>
                <th>Email</th>
                <th>I Am The</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {leads.map((lead) => {
                const { userData } = lead;

                return (
                  <tr key={lead.id}>
                    <td>{userData.lastName}</td>
                    <td>{userData.firstName}</td>
                    <td>{userData.email}</td>
                    <td>{userData.iAmThe}</td>
                    <td>
                      <GeneralButton
                        variant="warning"
                        size={ButtonSize.SMALL}
                        style={{ margin: "0 0 0 0" }}
                        onClick={() => navigate(`/leads/${lead.id}`)}
                      >
                        More Details
                      </GeneralButton>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default LeadsPage;
