import { useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/user-context";
import { isSuperAdmin } from "pages/logged-in/home/logged-in-home-utils";

export const useAppNavigate = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  return (path, options = {}) => {
    if (isSuperAdmin(user)) {
      navigate(`/admin/${path.replace(/^\//, "")}`, options);
    } else if (user?.teamCode) {
      navigate(`/app/${user.teamCode}/${path.replace(/^\//, "")}`, options);
    } else {
      navigate(path, options);
    }
  };
};
