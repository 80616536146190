export const getTeamLeads = async (api, teamCode) => {
  try {
    const response = await api.get(`/team/${teamCode}/leads`);
    return response.data.data;
  } catch (err) {
    console.error("Failed to fetch leads");
    return [];
  }
};

export const getLeadById = async (api, teamCode, leadId) => {
  try {
    const response = await api.get(`/team/${teamCode}/lead/${leadId}`);
    return response.data.data;
  } catch (err) {
    console.error("Failed to fetch lead", err);
    return null;
  }
};
