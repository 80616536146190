import React from "react";
import Button from "react-bootstrap/Button";

function GeneralSubmitButton({ style, text, icon, onClick, variant }) {
  return (
    <Button
      variant={variant || "warning"}
      className="btn-lg form-control"
      type="button"
      style={
        style
          ? style
          : {
            margin: "16px 0 16px 0",
            borderRadius: "30px"
          }
      }
      onClick={onClick}
    >
      {icon}
      {icon && " "}
      <span className="align-middle">{text}</span>
    </Button>
  );
}

export default GeneralSubmitButton;