import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import UsersAdmin from "./users-admin";
import SettingsPage from "pages/logged-in/settings";
import TeamsAdmin from "./teams/teams-admin";

function AdminHome() {
  return (
    <Routes>
      <Route index element={<Navigate to="teams" replace />} />
      <Route path="teams" element={<TeamsAdmin />} />
      <Route path="users" element={<UsersAdmin />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="*" element={<Navigate to="teams" replace />} />
    </Routes>
  );
}

export default AdminHome;
