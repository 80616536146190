import React from "react";
import AddMemberModal from "./add-member-modal";
import ActivateMemberModal from "./activate-member-modal";
import EditMemberModal from "./edit-member-modal";
import RemoveMemberModal from "./remove-member-modal";

export const ModalTypes = {
  AddMember: "addMember",
  EditMember: "editMember",
  ActivateMember: "activateMember",
  RemoveMember: "removeMember",
};

function TeamModalController({
  show,
  onHide,
  modalType,
  refetchTeam,
  selectedUser,
  setModalType,
}) {
  const renderModalContent = () => {
    switch (modalType) {
      case ModalTypes.AddMember:
        return (
          <AddMemberModal
            onClose={onHide}
            refetchTeam={refetchTeam}
            show={show}
          />
        );
      case ModalTypes.ActivateMember:
        return (
          <ActivateMemberModal
            onClose={onHide}
            refetchTeam={refetchTeam}
            selectedUser={selectedUser}
            setModalType={setModalType}
            show={show}
          />
        );
      case ModalTypes.EditMember:
        return (
          <EditMemberModal
            show={show}
            onClose={onHide}
            refetchTeam={refetchTeam}
            selectedUser={selectedUser}
            setModalType={setModalType}
          />
        );
      case ModalTypes.RemoveMember:
        return (
          <RemoveMemberModal
            show={show}
            onClose={onHide}
            refetchTeam={refetchTeam}
            selectedUser={selectedUser}
          />
        );
      default:
        return null;
    }
  };

  return renderModalContent();
}

export default TeamModalController;
