import React, { useState } from "react";
import { Form, ListGroup } from "react-bootstrap";
import useApi from "hooks/api";
import GeneralButton, { ButtonSize } from "components/buttons/general-button";

function AddTeamOwnerWidget({ updateOwners }) {
  const [email, setEmail] = useState("");
  const [owners, setOwners] = useState([]);
  const api = useApi();

  const handleAddOwner = async () => {
    try {
      const response = await api.get(`/user/exists/${email}`);
      if (response.data) {
        const newOwners = [...owners, email];
        setOwners(newOwners);
        updateOwners(newOwners);
        setEmail("");
      }
    } catch (error) {
      console.error("Error adding owner:", error);
    }
  };

  return (
    <>
      <Form.Group className="mb-3 d-flex">
        <Form.Control
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <GeneralButton onClick={handleAddOwner} disabled={!email} size={ButtonSize.SMALL} variant="warning">
          Add Owner
        </GeneralButton>
      </Form.Group>
      <ListGroup>
        {owners.map((owner, index) => (
          <ListGroup.Item key={index}>{owner}</ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
}

export default AddTeamOwnerWidget;
