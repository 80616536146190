import useDeviceSize from "hooks/device-size";
import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";

const LoadingSpinner = ({ loadingText = "Loading" }) => {
  const [numDots, setNumDots] = useState(0);
  const { isMobile } = useDeviceSize();

  useEffect(() => {
    const interval = setInterval(() => {
      setNumDots((prevDots) => prevDots === 3 ? 0 : prevDots + 1); 
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const spinnerSize = isMobile ? "2rem" : "4rem";

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div style={{ width: spinnerSize, height: spinnerSize }}>
        <Spinner
          animation="border"
          style={{ width: "100%", height: "100%" }}
          variant="warning"
        />
      </div>
      <p className="mt-3" style={{ fontSize: isMobile ? "1rem" : "1.25rem" }}>
        {loadingText}
        {".".repeat(numDots)}
      </p>
    </div>
  );
};

export default LoadingSpinner;
