function UnderConstruction({}) {
  return (
    <div>
      <div style={{height: '30px'}}/>
      <p 
        style={{
          display: 'inline'
        }}>
        PVBatts is currently under construction, or may be unsupported by your browser. 
        Please check our&nbsp;
      </p>
      <a
        href='https://discord.gg/RTUDJZTA2R'
        style={{display: 'inline'}}>
        Discord server
      </a>
      <p
        style={{
          display: 'inline'
        }}>  for updates, and consider disabling incognito mode or trying another browser.
      </p>
    </div>
  );
}

export default UnderConstruction;