import { Roles } from "../constants";

export const isSuperAdmin = (user) => {
  return user?.globalRoles?.includes(Roles.SUPER_ADMIN) || false;
};

export const isTeamOwner = (user, teamCode) => {
  return user?.teamRoles[teamCode] === Roles.TEAM_OWNER;
};

export const isTeamMember = (user, teamCode) => {
  return user?.teamRoles[teamCode] === Roles.TEAM_MEMBER;
};

export const isPending = (user, teamCode) => {
  return user?.teamRoles[teamCode] === Roles.PENDING;
};
