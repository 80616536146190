import React from "react";
import Button from "react-bootstrap/Button";

export const ButtonSize = {
  SMALL: "sm",
  LARGE: "lg",
};

export const ButtonTypes = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  DANGER: "danger",
  WARNING: "warning",
  LINK: "link",
}

function GeneralButton({
  style,
  text,
  icon,
  onClick,
  variant,
  readOnly,
  children,
  size = ButtonSize.LARGE,
  fullWidth = false,
  rounded = false,
}) {
  const buttonSize = size === ButtonSize.SMALL ? "btn-sm" : "btn-lg";

  return (
    <Button
      variant={variant || "secondary"}
      className={`btn ${buttonSize} ${fullWidth ? "w-100" : ""}`}
      type="button"
      style={{
        margin: "16px 0 16px 0",
        borderRadius: rounded ? "30px" : "8px",
        minWidth: "120px",
        ...style,
      }}
      onClick={onClick}
      disabled={readOnly === true}
    >
      {icon && <span>{icon} </span>}
      <span className="align-middle">{text || children}</span>
    </Button>
  );
}

export default GeneralButton;
