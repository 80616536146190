import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useUserContext } from "contexts/user-context";
import { getRoleForTeam, getTeamByTeamCode } from "./team-utils";
import useApi from "hooks/api";
import { isTeamOwner } from "../home/logged-in-home-utils";
import LoadingSpinner from "components/widgets/loading-spinner";
import MemberTable from "./components/member-table";
import GeneralButton from "components/buttons/general-button";
import TeamModalController, { ModalTypes } from "./components/modals/team-modal-controller";

function TeamPage() {
  const api = useApi();
  const { user } = useUserContext();
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [activeMembers, setActiveMembers] = useState([]);
  const [pendingMembers, setPendingMembers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleOpenModal = (type, callback = () => null) => {
    setModalType(type);
    setShowModal(true);
    callback();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalType(null);
    setSelectedUser(null);
  };

  const fetchTeam = useCallback(async () => {
    setIsLoading(true);
    try {
      const responseData = await getTeamByTeamCode(api, user.teamCode);
      const { members, team } = responseData;

      setActiveMembers(members.filter((member) => getRoleForTeam(member, team.teamCode) !== "pending"));
      setPendingMembers(members.filter((member) => getRoleForTeam(member, team.teamCode) === "pending"));
    } catch (error) {
      console.error("Error fetching team:", error);
    } finally {
      setIsLoading(false);
    }
  }, [api, user.teamCode]);

  useEffect(() => {
    fetchTeam();
  }, [fetchTeam]);

  const isOwner = isTeamOwner(user, user.teamCode);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Container>
        <Row className="mt-4 mb-4 align-items-center">
          <Col>
            <h2>My Team</h2>
          </Col>
          {isOwner &&
            <Col xs="auto" className="ml-auto">
              <GeneralButton
                text="Add Team Member"
                variant="warning"
                onClick={() => handleOpenModal(ModalTypes.AddMember)}
              />
            </Col>
          }
        </Row>
      </Container>

      {isOwner && pendingMembers?.length > 0 && (
        <>
          <Row>
            <Col>
              <h3>Pending Members</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <MemberTable
                handleOpenModal={handleOpenModal}
                isOwner={isOwner}
                members={pendingMembers}
                pending={true}
                setSelectedUser={setSelectedUser}
              />
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col>
          <h3>Team Members</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <MemberTable
            handleOpenModal={handleOpenModal}
            members={activeMembers}
            isOwner={isOwner}
            setSelectedUser={setSelectedUser}
          />
        </Col>
      </Row>
      <TeamModalController
        modalType={modalType}
        onHide={handleCloseModal}
        refetchTeam={fetchTeam}
        selectedUser={selectedUser}
        setModalType={setModalType}
        show={showModal}
      />
    </Container>
  );
}


export default TeamPage;
