import GeneralButton, { ButtonSize } from "components/buttons/general-button";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { getAllTeams } from "./teams-admin-utils";
import useApi from "hooks/api";
import LoadingSpinner from "components/widgets/loading-spinner";
import TeamAdminModal from "./teams-admin-modal/teams-admin-modal";

function TeamsAdmin() {
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const fetchTeams = useCallback( async () => {
    try {
      const teamsData = await getAllTeams(api);
      setTeams(teamsData.data);
    } catch (err) {
      console.error("Failed to fetch Teams");
    } finally {
      setLoading(false);
    }
  }, [api])
  
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTeam(null);
  };

  useEffect(() => {
    fetchTeams();
  }, [api, fetchTeams]);

  if (loading) {
    return <LoadingSpinner />
  };

  return (
    <>
      <Container>
        <Row className="mt-4 mb-4 align-items-center">
          <Col>
            <h2>Teams</h2>
          </Col>
          <Col xs="auto" className="ml-auto">
            <GeneralButton
              text="Create Team"
              variant="primary"
              onClick={() => setShowModal(true)}
            />
          </Col>
        </Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Team Code</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {teams?.map((team) => {
              return (
                <tr key={team.id}>
                  <td>{team.name}</td>
                  <td>{team.teamCode}</td>
                  <td>{team.description}</td>
                  <td>
                    <GeneralButton
                      variant="warning"
                      size={ButtonSize.SMALL}
                      style={{ margin: "0 0 0 0" }}
                      onClick={() => {
                        setSelectedTeam(team);
                        setShowModal(true);
                      }}
                    >
                      More Details
                    </GeneralButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
      <TeamAdminModal
        onClose={handleCloseModal}
        refetchTeam={fetchTeams}
        show={showModal}
        team={selectedTeam}
      />
    </>
  ); 
}

export default TeamsAdmin;
