import axios from 'axios';
import { serverAddress } from '../configs/env.js';

export async function registerUser(
  api,
  newUserData,
  navigate
) {
  const path = "auth/register/";

  const response = await api.post(path, newUserData);

  if (response.statusText !== "OK") {
    throw new Error(`An error has occurred: ${response.statusText}`);
  }

  navigate("/login");
}

export const loginUser = (api, loginData, setUser) => {
  const path = "auth/login/";

  api
    .post(path, loginData, { withCredentials: true })
    .then((response) => {
      const { user } = response.data.data;

      setUser(user);
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function inviteUser(email, referrer, callback) {
  const fullPath = `${serverAddress}/user/invite/`
  const body = { 
    newUserEmail: email,
    referrer: referrer
  };
  try {
    axios.post(fullPath, body)
      .then(response => {
        if (response.statusText !== 'OK' || response.data.errors) {
          const message = `An error has occurred: ${response.statusText}`;
          window.alert(message);
          return callback();
        }
        else {
          return callback(response.data.message);
        }
      }
    );
  } catch (errors) {
    return callback(console.log(errors));
  }
}