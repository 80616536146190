import React from "react";

import useDeviceSize from "hooks/device-size";
import CurrencyInput from "components/inputs/currency";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import SingleCheckbox from "components/inputs/single-checkbox";
import { SUGGEST_CHECKBOX_OPTIONS, SYSTEM_COST_OPTIONS, SystemCostLabels } from "./constants";
import FinancingDropdowns from "./financing-dropdowns";

const inputStyle = {
  margin: 0,
}

function SystemCost({
  formData,
  setFieldValue
}) {
  const { isMobile,  isTablet } = useDeviceSize();
  const currencyLabelStyle = {
    fontSize: !isMobile ? '22px' : '16px',
    fontWeight: 600,
    paddingRight: "0px"
  }
  const labelSize = !isMobile ? "large" : "";
  
  // These turned out to be different enough it may have been better to have them all as separately defined components
  // May refactor to that at some point, but I was too in the weeds to go back.
  const generateFields = () => {
    return (
      <>
        {SYSTEM_COST_OPTIONS.map((option) => {
          const { label, fieldName, suggestFieldName, readOnly } = option;

          if (option.label === SystemCostLabels.FinanceDetails) {
            return (
              <FinancingDropdowns
                key="financing-dropdowns"
                formData={formData}
                setFieldValue={setFieldValue}
              />
            );
          } else {
            return (
              <Row key={label}>
                <Col xs={isMobile ? 12 : 11}>
                  <CurrencyInput
                    fieldName={fieldName}
                    key={label}
                    htmlName={label}
                    labelComponent={
                      isMobile &&
                      SUGGEST_CHECKBOX_OPTIONS.includes(label) && (
                        <SingleCheckbox
                          checked={formData[suggestFieldName]}
                          label="Suggest?"
                          onChange={() => {
                            setFieldValue(fieldName, formData[fieldName])
                            setFieldValue(suggestFieldName, !formData[suggestFieldName]);
                          }}
                          style={{ justifyContent: "flex-end" }}
                        />
                      )
                    }
                    labelStyle={currencyLabelStyle}
                    labelPosition={!isMobile && "adjacent"}
                    placeholder="$0"
                    prefix="$"
                    readOnly={readOnly || formData[suggestFieldName]}
                    setFieldValue={setFieldValue}
                    style={inputStyle}
                    value={formData[fieldName]}
                  />
                </Col>
                <Col xs={isMobile ? 0 : 1}>
                  {!isMobile && SUGGEST_CHECKBOX_OPTIONS.includes(label) && (
                    <SingleCheckbox
                      checked={formData[suggestFieldName]}
                      label="Suggest?"
                      onChange={() => {
                        setFieldValue(fieldName, formData[fieldName])
                        setFieldValue(suggestFieldName, !formData[suggestFieldName])
                      }}
                    />
                  )}
                </Col>
              </Row>
            );
          }
        })
        }
      </>
    );
  };

  return (
    <Container className={`pt-4 ${isMobile && 'px-0'}`}>
      <Row>
        <Col xs={isMobile ? 0 : isTablet ? 1 : 2} />
        <Col xs={isMobile ? 12 : isTablet ? 10 : 8} className="text-right">
          {generateFields()}
        </Col>
        <Col xs={isMobile ? 0 : isTablet ? 1 : 2} />
      </Row>
    </Container>
  );
}

export default SystemCost;
