import React from "react";
import Form from "react-bootstrap/Form";

function RHFEmailInput({
  label,
  name,
  placeholder,
  register,
  error,
  disabled = false,
  required = false,
}) {
  return (
    <Form.Group className="mb-3">
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type="email"
        placeholder={placeholder || label}
        {...register(name)}
        disabled={disabled}
        required={required}
        isInvalid={!!error}
      />
      <Form.Control.Feedback type="invalid">
        {error || "Please enter a valid email"}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export default RHFEmailInput;
