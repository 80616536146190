export const REF_KEYS = {
  BasicBatteryOptions: "basicBatteryOptions",
  BasicInverterOptions: "basicInverterOptions",
  BasicSolarOptions: "basicSolarOptions",
  BatteryMountStyleOptions: "batteryMountStyleOptions",
  BatteryRackingSystemOptions: "batteryRackingSystemOptions",
  BillRateOptions: "billRateOptions",
  ConsumptionRateOptions: "consumptionRateOptions",
  DailyMinimumCycleOptions: "dailyMinimumCycleOptions",
  EnvironmentOptions: "environmentOptions",
  IpRatingOptions: "ipRatingOptions",
  NemaRatingOptions: "nemaRatingOptions",
  OcpdTypeOptions: "ocpdTypeOptions",
  PortTypeOptions: "portTypeOptions",
};

export const pvWattsFields = {
  BatteryCharge: "batteryCharge",
  BatteryChargeConsumption: "batteryChargeConsumption",
  BatteryChargeCost: "batteryChargeCost",
  BatteryChargeFromSolar: "batteryChargeFromSolar",
  DollarSavings: "dollarSavings",
  DollarSellback: "dollarSellback",
  Editable: "editable",
  FinalCondition: "finalCondition",
  GenDays: "genDays",
  GridConsumption: "gridConsumption",
  GridExport: "gridExport",
  GridSellback: "gridSellback",
  Month: "month",
  NetDollarSavings: "netDollarSavings",
  NetGridExport: "netGridExport",
  NewDemand: "newDemand",
  NewImportRate: "newImportRate",
  NewRawEnergyCost: "newRawEnergyCost",
  OldEnergyCost: "oldEnergyCost",
  SiteConsumption: "siteConsumption",
  SolarOffset: "solarOffset",
  SystemConsumption: "systemConsumption",
  TotalCost: "totalCost",
};