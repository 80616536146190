function RegistrationPendingPage() {
  return (
    <div style={{ paddingLeft: "16px" }}>
      Your registration is pending. Please contact your team owner for
      activation.
    </div>
  );
}

export default RegistrationPendingPage;
