import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import RangeSlider from 'react-bootstrap-range-slider';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { htmlStringToDisplay } from 'utils/lib.js';
import { questionMarkIcon } from '../objects/icons.jsx';

function SliderInput({style, htmlName, displayName, fieldName, value, min, max, step, readOnlyTooltip, sliderLabelSingle, sliderLabelPlural, displayLabel, setFieldValue, readOnly, colWidth, isMobile}) {
  const [sliderValue, setSliderValue] = React.useState(value); 
  const label = displayName || htmlStringToDisplay(htmlName);
  const renderTooltip = props => (
    <Tooltip {...props}>{ readOnlyTooltip }</Tooltip>
  );
  return (
    <Col
      xl={colWidth}
      style={style ? style : {
        marginBottom: '20px',
        marginTop: '20px'
      }}>
      <Row
        className='align-items-center'>
        <Col 
          xl={isMobile ? 9 : 8}
          style={{display: 'inline-block'}}>
          <Container>
            <Container>
            <RangeSlider
              style={{marginTop: '34px'}}
              className='form-control'
              id={htmlName}
              tooltip='on'
              variant='secondary'
              tooltipLabel={currentValue => `${currentValue} ${currentValue === 1 ? sliderLabelSingle : sliderLabelPlural}`}
              tooltipPlacement='top'
              value={sliderValue}
              onChange={(e) => {
                setSliderValue(e.target.value);
              }}
              onAfterChange={(e) => {
                setFieldValue(fieldName, e.target.value);
              }}
              min={min || 0}
              step={step || 1}
              max={max}
              disabled={readOnly === true}/>
            </Container>
          </Container>
        </Col>
        <Form.Group
          as={Col}
          xl={isMobile ? 3 : 4}
          style={{
            display: 'inline-block'
          }}>
          {
            !isMobile ? 
            <Form.Label
              htmlFor={htmlName}
              className='big-font'> { label + ': ' }
              <span>
                {
                  readOnlyTooltip ? 
                  (
                    <div style={{
                      display: 'inline',
                      marginLeft: '6px',
                    }}>
                      <OverlayTrigger placement="top" overlay={renderTooltip}>
                        { questionMarkIcon }
                      </OverlayTrigger>
                    </div>
                  ) : 
                  (<div/>)
                }
              </span>
            </Form.Label> : null
          }
          <Form.Control
            id={htmlName + '-display'}
            disabled={true}
            style={{
              textAlign: 'center'
            }}
            value={displayLabel}>
          </Form.Control>
        </Form.Group>
      </Row>
    </Col>
  );
}

export default SliderInput;