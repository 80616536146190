import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import GeneralSubmitButton from 'components/buttons/general-submit-button.jsx';
import { lightningIcon } from 'components/objects/icons.jsx';

import { validateMailingListSignup } from 'utils/validations.js';
import { isEmpty } from 'utils/lib.js';
import useDeviceSize from 'hooks/device-size';
import { useSuccessNotification } from 'hooks/success-notification.js';
import useApi from 'hooks/api.js';
import { getDesignPdfBlob, sendDesignPdf, signUserUp } from './sign-up-utils.js';
import { useErrorNotification } from 'hooks/error-notification.jsx';
import { useRefs } from 'contexts/refs-context.jsx';

export const MailingListSignup = ({
  formData,
  rateStructures,
  setFieldValue,
  setGeneratingEmailReport,
  toggleWidget,
  emailReportRef,
  signupTermsOfUse
}) => {
  //DEFAULT TOU:
  const TOU = signupTermsOfUse ? signupTermsOfUse : 'By clicking “Email Report,” I consent to PVBatts providing me information on PVBatts products. Click unsubscribe in any email to be removed from email contact. Consent is not a condition of purchase. I understand this report is an estimate only and does not guarantee a final system price or economics. This report is not an installation quote. Please see our Privacy Statement and Terms of Use.'
  const { isMobile } = useDeviceSize();
  const { notifySuccess } = useSuccessNotification();
  const { notifyError } = useErrorNotification();
  const api = useApi();
  const { refs } = useRefs();

  const formPassesValidation = () => {
    const validationErrors = validateMailingListSignup(formData);
    return isEmpty(validationErrors);
  }

  const onButtonClick = async () => {
    if (formPassesValidation()) {
      setGeneratingEmailReport(true);
      window.scrollTo(0, 0);

      try {
        const designPdfBlob = await getDesignPdfBlob(emailReportRef);

        await sendDesignPdf(formData.email, designPdfBlob, api);
        await signUserUp(formData, rateStructures, refs, designPdfBlob, api);

        setTimeout(() => {
          notifySuccess(
            "Your report has been submitted. Please check your email in 30 minutes."
          );
          toggleWidget("showEmailWidget");
          setGeneratingEmailReport(false);
        }, 1000);
      } catch (error) {
        notifyError("An error occurred while processing your request. Please try again and contact the PVBatts team if the issue persists.");
        console.error("Error processing request:", error);
        setGeneratingEmailReport(false);
      }
    }
  };

  return (
    <Form
      style={{
        marginBottom: '16px'
      }}>
      <Container>
        <p style={{
          fontSize: !isMobile ? '22px': '16px',
          textAlign: 'justify'
        }}>
            {TOU}
        </p>
        <Row>
          <Col xl={4}>
            <Form.Control
              id='sign-up-first-name-input'
              type='text'
              style={{
                marginTop: '10px'
              }}
              placeholder='First name'
              value={formData.firstName}
              onChange={e => { setFieldValue('firstName',e.target.value) }}
              required/>
            <Form.Control.Feedback type="invalid">
              Please enter your first name
            </Form.Control.Feedback>
          </Col>
          <Col xl={4}>
            <Form.Control
              id='sign-up-last-name-input'
              type='text'
              style={{
                marginTop: '10px'
              }}
              placeholder='Last name'
              value={formData.lastName}
              onChange={e => { setFieldValue('lastName',e.target.value) }}
              required/>
            <Form.Control.Feedback type="invalid">
              Please enter your last name
            </Form.Control.Feedback>
          </Col>
          <Col xl={4}>
            <Form.Control
              id='sign-up-email-input'
              type='email'
              style={{
                marginTop: '10px'
              }}
              placeholder='Email address'
              value={formData.email}
              onChange={e => { setFieldValue('email',e.target.value) }}
              required/>
            <Form.Control.Feedback type="invalid">
              Please enter your full email address
            </Form.Control.Feedback>
            <Form.Check
                style={{
                  backgroundColor: 'transparent',
                  borderStyle: 'none',
                  marginTop: '10px'
                }}
                type='checkbox'
                id='confirm-sign-up-checkbox'>
                <Form.Check.Input
                  type={'checkbox'}
                  checked={formData.agreedToTerms}
                  onChange={() => { setFieldValue('agreedToTerms', !formData.agreedToTerms) }}
                  required/>
                <Form.Check.Label
                  style={{
                    verticalAlign: 'top',
                    color: 'black'
                  }}> 
                  I agree to the terms
                </Form.Check.Label>
                <Form.Control.Feedback type="invalid">
                  Please sign up to receive your free detailed report
                </Form.Control.Feedback>
              </Form.Check>
          </Col>
        </Row>
        <Row>
          <Form.Group as={Row}>
            <Form.Label htmlFor='i-am-the-radios' column xs={isMobile ? 4 : 2}>I am the:</Form.Label>
            <Col xs={isMobile ? 8 : 10} style={{marginTop: '10px'}}>
              <Form.Check
                inline
                id='i-am-the-radios'
                type='radio'
                label='Homeowner'
                onChange={(e) => {
                  setFieldValue('iAmThe','homeowner')
                }}
                checked={formData.iAmThe === 'homeowner'}
              />
              <Form.Check
                inline
                type='radio'
                label='Company'
                onChange={(e) => {
                  setFieldValue('iAmThe','company')
                }}
                checked={formData.iAmThe === 'company'}
              />
            </Col>
          </Form.Group>
        </Row>
        {
          formData.iAmThe === 'company' ? ( 
            <Row>
              <Form.Group as={Row}>
                <Form.Label htmlFor='i-work-in-radios' column xs={isMobile ? 4 : 2}>I work in:</Form.Label>
                <Col xs={isMobile ? 8 : 10} style={{marginTop: '10px'}}>
                  <Form.Check
                    id='i-work-in-radios'
                    inline
                    type='radio'
                    label='Sales'
                    onChange={(e) => {
                      setFieldValue('iWorkIn','sales')
                    }}
                    checked={formData.iWorkIn === 'sales'}
                  />
                  <Form.Check
                    inline
                    type='radio'
                    label='Design'
                    onChange={(e) => {
                      setFieldValue('iWorkIn','design')
                    }}
                    checked={formData.iWorkIn === 'design'}
                  />
                  <Form.Check
                    inline
                    type='radio'
                    label='Other'
                    onChange={(e) => {
                      setFieldValue('iWorkIn','other')
                    }}
                    checked={formData.iWorkIn === 'other'}
                  />
                </Col>
              </Form.Group>
            </Row>
          ) : null
        }
        <Form.Control
          style={{
            marginTop: '14px'
          }}
          placeholder='Add notes here'
          value={formData.signupNotes}
          onChange={(e) => {
            setFieldValue('signupNotes', e.target.value)
          }}/>
        <Row 
          style={{
            marginTop:'20px',
            marginBottom: '12px'
          }}>
          <GeneralSubmitButton
            icon={lightningIcon}
            text='Send Report'
            onClick={onButtonClick}/>
        </Row>
      </Container>
    </Form>
  );
}

export default MailingListSignup;
    