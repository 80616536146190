import React, { createContext, useCallback, useContext, useState } from "react";

const SuccessContext = createContext();

export const useSuccessNotification = () => useContext(SuccessContext);

export const SuccessProvider = ({ children }) => {
  const [successMessage, setSuccessMessage] = useState(null);

  const notifySuccess = useCallback((message) => {
    setSuccessMessage(message);
  }, []);

  const clearSuccessMessage = useCallback(() => {
    setSuccessMessage(null);
  }, []);

  return (
    <SuccessContext.Provider value={{ successMessage, notifySuccess, clearSuccessMessage, }}>
      {children}
    </SuccessContext.Provider>
  );
};
